<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="relative flex flex-row px-3 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span
        v-if="selectedMinPrice === 0 && selectedMaxPrice === 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ title }}
      </span>

      <!-- Filter Subname Content -->
      <span
        v-if="selectedMinPrice > 0 && selectedMaxPrice === 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        <b>{{ formatPrice(selectedMinPrice) }}</b> - Max
      </span>

      <span
        v-if="selectedMinPrice > 0 && selectedMaxPrice < 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        <b>{{ formatPrice(selectedMinPrice) }}</b> -
        <b>{{ formatPrice(selectedMaxPrice) }}</b>
      </span>

      <span
        v-if="selectedMinPrice === 0 && selectedMaxPrice < 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        Min - <b>{{ formatPrice(selectedMaxPrice) }}</b>
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right w-full lg:w-96 shadow-lg bg-white rounded-md"
    >
      <div class="flex">
        <div class="w-1/2 lg:w-48 pt-6 pl-4 pb-3 pr-4">
          <div
            v-if="
              user.loggedIn &&
              user.profile.answeredDiscountSurvey &&
              this.settings.allowGlobalDiscount
            "
          >
            <!-- Filter Drop Down MIN Content && Global DiscountType === Amount -->
            <div
              v-if="
                globals[0].globalDiscountType === 'amount' &&
                globals[0].allowGlobalDiscount
              "
            >
              <p class="mb-4 font-semibold">Min</p>
              <ul
                class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
              >
                <li
                  v-for="price in priceListFull"
                  :key="price"
                  @click="
                    setMinPrice(price - globals[0].globalDiscountAmount - 10)
                  "
                  class="mb-2"
                >
                  <div
                    class="mb-2 fontBoldSelect"
                    v-if="
                      selectedMinPrice + 10 ===
                      price - globals[0].globalDiscountAmount
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price - globals[0].globalDiscountAmount,
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      selectedMinPrice + 10 !==
                      price - globals[0].globalDiscountAmount
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price - globals[0].globalDiscountAmount,
                      )
                    }}
                  </div>
                </li>
              </ul>

              <!-- Filter Drop Down MIN Content && Global DiscountType === Amount && Custom Price -->
              <div v-if="globals[0].isCustomPrice" class="w-full">
                <input
                  @keyup.enter="setMinPrice(customMinPriceInput)"
                  v-model="customMinPriceInput"
                  id="input-outline"
                  type="number"
                  placeholder="Custom Price"
                  class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                  autocomplete="off"
                />
              </div>
            </div>
            <!-- Filter Drop Down MIN Content && Global DiscountType === Percentage -->
            <div
              v-if="
                globals[0].globalDiscountType === 'percentage' &&
                globals[0].allowGlobalDiscount
              "
            >
              <p class="mb-4 font-semibold">Min</p>
              <ul
                class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
              >
                <li
                  v-for="price in priceListFull"
                  :key="price"
                  @click="
                    setMinPrice(
                      price * ((100 - globals[0].globalDiscountAmount) / 100) -
                        10,
                    )
                  "
                  class="mb-2"
                  v-bind:class="{ selected: selectedMinPrice }"
                >
                  <div
                    class="mb-2 fontBoldSelect"
                    v-if="
                      selectedMinPrice + 10 ===
                      price * ((100 - globals[0].globalDiscountAmount) / 100)
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price * ((100 - globals[0].globalDiscountAmount) / 100),
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      selectedMinPrice + 10 !==
                      price * ((100 - globals[0].globalDiscountAmount) / 100)
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price * ((100 - globals[0].globalDiscountAmount) / 100),
                      )
                    }}
                  </div>
                </li>
              </ul>

              <!-- Filter Drop Down MIN Content && Global DiscountType === Percentage && Custom Price -->
              <div v-if="globals[0].isCustomPrice" class="w-full">
                <input
                  @keyup.enter="setMinPrice(customMinPriceInput)"
                  v-model="customMinPriceInput"
                  id="input-outline"
                  type="number"
                  placeholder="Custom Price"
                  class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <!-- Filter Drop Down MIN Content && Global DiscountType === NONE -->
          <div v-else>
            <p class="mb-4 font-semibold">Min</p>
            <ul
              class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
            >
              <li
                v-for="price in priceListFull"
                :key="price"
                @click="setMinPrice(price - 10)"
                class="mb-2"
                v-bind:class="{ selected: selectedMinPrice }"
              >
                <div
                  class="mb-2 fontBoldSelect"
                  v-if="selectedMinPrice + 10 === price"
                >
                  {{ $filters.prettyCurrency(price) }}
                </div>
                <div v-if="selectedMinPrice + 10 !== price">
                  {{ $filters.prettyCurrency(price) }}
                </div>
              </li>
            </ul>

            <div v-if="globals[0].isCustomPrice" class="w-full">
              <input
                @keyup.enter="setMinPrice(customMinPriceInput)"
                v-model="customMinPriceInput"
                id="input-outline"
                type="number"
                placeholder="Custom Price"
                class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="w-1/2 lg:w-48 pt-6 pl-4 pb-3 pr-4">
          <div
            v-if="
              user.loggedIn &&
              user.profile.answeredDiscountSurvey &&
              this.settings.allowGlobalDiscount
            "
          >
            <!-- Filter Drop Down MAX Content && Global DiscountType === Amount -->
            <div
              v-if="
                globals[0].globalDiscountType === 'amount' &&
                globals[0].allowGlobalDiscount
              "
            >
              <p class="mb-4 font-semibold">Max</p>
              <ul
                class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
              >
                <li
                  v-for="price in priceListFull"
                  :key="price"
                  @click="
                    setMaxPrice(price - globals[0].globalDiscountAmount + 10)
                  "
                  class="mb-2"
                >
                  <div
                    class="mb-2 fontBoldSelect"
                    v-if="
                      selectedMaxPrice - 10 ===
                      price - globals[0].globalDiscountAmount
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price - globals[0].globalDiscountAmount,
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      selectedMaxPrice - 10 !==
                      price - globals[0].globalDiscountAmount
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price - globals[0].globalDiscountAmount,
                      )
                    }}
                  </div>
                </li>
              </ul>

              <!-- Filter Drop Down MAX Content && Global DiscountType === Amount && Custom Price -->
              <div v-if="globals[0].isCustomPrice" class="w-full">
                <input
                  @keyup.enter="setMaxPrice(customMaxPriceInput)"
                  v-model="customMaxPriceInput"
                  id="input-outline"
                  type="number"
                  placeholder="Custom Price"
                  class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                  autocomplete="off"
                />
              </div>
            </div>

            <!-- Filter Drop Down MAX Content && Global DiscountType === Percentage -->
            <div
              v-if="
                globals[0].globalDiscountType === 'percentage' &&
                globals[0].allowGlobalDiscount
              "
            >
              <p class="mb-4 font-semibold">Max</p>
              <ul
                class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
              >
                <li
                  v-for="price in priceListFull"
                  :key="price"
                  @click="
                    setMaxPrice(
                      price * ((100 - globals[0].globalDiscountAmount) / 100) +
                        10,
                    )
                  "
                  class="mb-2"
                >
                  <div
                    class="mb-2 fontBoldSelect"
                    v-if="
                      selectedMaxPrice - 10 ===
                      price * ((100 - globals[0].globalDiscountAmount) / 100)
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price * ((100 - globals[0].globalDiscountAmount) / 100),
                      )
                    }}
                  </div>
                  <div
                    v-if="
                      selectedMaxPrice - 10 !==
                      price * ((100 - globals[0].globalDiscountAmount) / 100)
                    "
                  >
                    {{
                      $filters.prettyCurrency(
                        price * ((100 - globals[0].globalDiscountAmount) / 100),
                      )
                    }}
                  </div>
                </li>
              </ul>

              <!-- Filter Drop Down MAX Content && Global DiscountType === Percentage && Custom Price -->
              <div v-if="globals[0].isCustomPrice" class="w-full">
                <input
                  @keyup.enter="setMaxPrice(customMaxPriceInput)"
                  v-model="customMaxPriceInput"
                  id="input-outline"
                  type="number"
                  placeholder="Custom Price"
                  class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <!-- Filter Drop Down MAX Content && Global DiscountType === NONE -->
          <div v-else>
            <p class="mb-4 font-semibold">Max</p>
            <ul
              class="max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
            >
              <li
                v-for="price in priceListFull"
                :key="price"
                @click="setMaxPrice(price + 10)"
                class="mb-2"
              >
                <div
                  class="mb-2 fontBoldSelect"
                  v-if="selectedMaxPrice - 10 === price"
                >
                  {{ $filters.prettyCurrency(price) }}
                </div>
                <div v-if="selectedMaxPrice - 10 !== price">
                  {{ $filters.prettyCurrency(price) }}
                </div>
              </li>
            </ul>

            <div v-if="globals[0].isCustomPrice" class="w-full">
              <input
                @keyup.enter="setMaxPrice(customMaxPriceInput)"
                v-model="customMaxPriceInput"
                id="input-outline"
                type="number"
                placeholder="Custom Price"
                class="relative w-full mt-4 p-2 placeholder-black bg-transparent text-sm border rounded border-surface outline-none focus:outline-none focus:shadow-outline"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Filter Drop Down MIN MAXContent &&  Custom Price Btns-->
      <div class="px-4 pb-4">
        <button
          v-if="customePriceHasInput"
          @click="setCustomPrice()"
          class="p-1 mt-2 w-full border rounded"
        >
          <span> Go </span>
        </button>
        <button
          v-else
          @click="toggleDropdown"
          class="bg-primary hover:bg-primary-600 p-1 mt-2 w-full border rounded text-white"
        >
          <span> Done </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash-es";
import { isNil } from "lodash-es";
import { settingsCollection, unitsCollection } from "@/firebaseCompat.js";
import { mapGetters } from "vuex";

export default {
  name: "MainUnitReserveListFilterMinMaxPrice",
  props: {
    selectedPrice: {
      default: null,
    },
    selectedMinPrice: {
      default: 0,
    },
    selectedMaxPrice: {
      default: 99000000,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
    },
    displayDropdown: false,
    placeholderText: "Min Price",
    customPriceInput: null,
    customMinPriceInput: null,
    customMaxPriceInput: null,
    priceList: [],
    prices: [{ price: "" }],
    dropdownRevealedAt: null,
  }),
  methods: {
    getPriceList() {
      unitsCollection.orderBy("price").onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (snap) => {
          let priceList = [];

          snap.forEach((doc) => {
            if (doc.data().price > 0) {
              priceList.push({
                price: doc.data().price,
                originalPrice: doc.data().originalPrice,
                purchasePrice: doc.data().purchasePrice,
              });
            }
          });
          this.priceList = priceList;
        },
      );
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot(
        {
          // Listen for document metadata changes
          includeMetadataChanges: true,
        },
        (doc) => {
          this.settings.priceFilterIncrement = doc.data().priceFilterIncrement;
          this.settings.allowReserve = doc.data().allowReserve;
          this.settings.allowDiscount = doc.data().allowDiscount;
          this.settings.displaySplashScreen = doc.data().displaySplashScreen;
          this.settings.splashScreenMessage = doc.data().splashScreenMessage;
          this.settings.launchDate = doc.data().launchDate
            ? doc.data().launchDate.toDate()
            : null;
          this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
          this.settings.setOneDayOnlyDiscount =
            doc.data().setOneDayOnlyDiscount || false;
          this.settings.allowGlobalDiscount =
            doc.data().allowGlobalDiscount || false;
          this.settings.globalDiscountAmount =
            doc.data().globalDiscountAmount || null;
          this.settings.globalDiscountType =
            doc.data().globalDiscountType || null;
        },
      );
    },
    formatPrice(num) {
      if (num >= 1000000000) {
        return "R " + (num / 1000000000).toFixed(1).replace(/\.0$/, "") + " G";
      }
      if (num >= 1000000) {
        return "R " + (num / 1000000).toFixed(1).replace(/\.0$/, "") + " mil";
      }
      if (num >= 1000) {
        return "R " + (num / 1000).toFixed(1).replace(/\.0$/, "") + " K";
      }
      return "R " + num;
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    setCustomPrice() {
      if (this.customMinPriceInput >= 0)
        this.setMinPrice(this.customMinPriceInput);
      if (this.customMaxPriceInput <= 99000000)
        this.setMaxPrice(this.customMaxPriceInput);
    },
    setMinPrice(price) {
      this.$emit("update:min-price-filter", { price: price, code: "minprice" });
      this.$route.query.minprice = encodeURI(price);
    },
    setMaxPrice(price) {
      this.$emit("update:max-price-filter", { price: price, code: "maxprice" });
      this.$route.query.maxprice = encodeURI(price);
    },
  },
  created() {
    this.getSettings();
    this.getPriceList();
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "units",
      globals: "globals",
    }),
    price: function () {
      return this.selectedPrice;
    },
    customePriceHasInput: function () {
      if (
        (!isNil(this.customMinPriceInput) &&
          !isEmpty(this.customMinPriceInput)) ||
        (!isNil(this.customMaxPriceInput) && !isEmpty(this.customMaxPriceInput))
      ) {
        return true;
      }

      return false;
    },
    priceListFull: function () {
      const uniquePrices = [];
      for (let i = 0; i < this.priceList.length; i++) {
        if (uniquePrices.indexOf(this.priceList[i].price) === -1) {
          uniquePrices.push(parseFloat(this.priceList[i].price));
        }
      }
      const prices = uniquePrices.sort(function (a, b) {
        return a - b;
      });
      const increment = parseFloat(this.globals[0].priceFilterIncrement);
      let accumulator = 0;
      const upperBound = prices[prices.length - 1];
      const lowerBound = prices[0];
      const incrementedPrices = [];
      while (accumulator < upperBound) {
        if (accumulator === 0) {
          accumulator = lowerBound;
          incrementedPrices.push(accumulator);
        } else {
          accumulator += increment;
          incrementedPrices.push(accumulator);
        }
      }
      return incrementedPrices;
    },
  },
};
</script>

<style>
#input-outline:focus {
  --tw-ring-color: var(--boarder-color);
  border-color: var(--boarder-color);
}

.fontBoldSelect {
  font-weight: bold;
}
</style>
