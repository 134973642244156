<template>
  <div id="main-unit-reserve-list-cards">
    <div class="flex flex-wrap items-center justify-center">
      <!-- Content -->
      <div
        v-for="unit in unitsProfile"
        :key="unit.id"
        class="flex-shrink-0 cardMargin md:mx-2 xl:mx-5 mb-6 relative overflow-hidden bg-surface-50 rounded-lg shadow-lg card-view-container"
      >
        <PendingOrReserveOrSoldLayout :unit="unit" />

        <!-- Shortlist Content -->
        <AddToShortlist
          class="absolute"
          :class="[
            !globals[0].isShortlistBtnRight
              ? 'shortlistLeft'
              : 'shortlistRight',
            ['reserved', 'sold'].includes(unit.status) ? 'z-0' : 'z-10',
          ]"
          :unitID="unit.id"
          :unitName="unit.name"
          :unit="unit"
          :show="true"
          :itemView="false"
          :showTooltip="false"
          :user="user"
          :shortListCount="unit.shortlistIncrement"
        />

        <!-- Guaranteed Rental Content -->
        <span
          v-if="unit.isGuaranteedRental"
          class="has-tooltip absolute z-10 p-1 font-semibold text-white rounded bg-green-500"
          :class="globals[0].isShortlistBtnRight ? 'grLeft' : 'grRight'"
        >
          GR

          <span
            class="tooltip font-normal"
            :class="
              globals[0].isShortlistBtnRight
                ? 'grTooltipLeft'
                : 'grTooltipRight'
            "
          >
            Guaranteed Rental
          </span>
        </span>

        <!-- Image Content -->
        <button
          class="w-full items-center tracking-wider duration-150 hover:bg-surface-600"
          v-on:click="
            $emit('update:unit-dialog-data', { open: true, data: unit })
          "
        >
          <img
            class="image-container"
            v-if="unit.images && unit.images.length > 0"
            :src="unit.images[0]?.s768 || unit.images[0]?.src"
            alt="Unit Image"
          />
          <img
            class="image-container"
            v-else
            src="../../assets/images/Launchbase_unit_default_image_grey.png"
            alt="Unit Image"
          />
        </button>

        <!-- Unit Details Content -->
        <div class="relative px-6 pb-6 mt-6">
          <div class="flex justify-between">
            <div class="text-left">
              <!-- Unit Name Content -->
              <span v-if="unit.name" class="block font-bold">
                {{
                  globals[0].isUnitNameLabel
                    ? globals[0].unitNameLabel !== null
                      ? `${globals[0].unitNameLabel} ${cutAfterHyphen(unit.name)}`
                      : cutAfterHyphen(unit.name)
                    : cutAfterHyphen(unit.name)
                }}
              </span>

              <!-- Unit Floor Content -->
              <span
                v-if="unit.floor && globals[0].layoutStyle[0] === 'estate'"
                class="block font-semibold text-sm"
              >
                {{ unit.floor }}
              </span>

              <span
                v-if="unit.standNumber && globals[0].isErfNumber"
                class="unit-view-text"
              >
                <p class="mr-1">Erf {{ unit.standNumber }}</p>
              </span>

              <span
                class="unit-view-text flex flex-wrap w-48"
                style="word-wrap: break-word; min-height: 2.5em"
              >
                <p
                  class="mr-1"
                  style="word-wrap: break-word; min-height: 2.5em"
                >
                  {{
                    unit.unitType && globals[0].isTypeCard
                      ? unit.unitType
                      : null
                  }}
                  {{
                    globals[0].isTypeCard && globals[0].isAspect ? ` | ` : null
                  }}
                  {{ unit.aspect && globals[0].isAspect ? unit.aspect : null }}
                  {{
                    globals[0].isViewEstate &&
                    (globals[0].isAspect || globals[0].isTypeCard)
                      ? ` | `
                      : null
                  }}
                  {{
                    unit.viewEstate && globals[0].isViewEstate
                      ? unit.viewEstate
                      : null
                  }}
                </p>
              </span>
            </div>

            <!-- Unit Price Content -->
            <div
              v-if="globals[0].isPrice"
              class="text-right overflow-visible absolute right-6"
            >
              <UnitPrice
                :unit="unit"
                :settings="globals[0]"
                :bigPrice="false"
                :normalPrice="true"
                :show="true"
                :tableStyle="false"
              />
            </div>
          </div>
        </div>

        <!-- Unit Spec Content -->
        <div class="unit-spec-icons-container">
          <div
            v-if="
              unit.bedrooms && unit.bedrooms > 0 && globals[0].isBedroomsCard
            "
            class="has-tooltip unit-spec-icon"
          >
            <BedroomIcon />
            <span class="font-semibold">{{ unit.bedrooms }}</span>
            <span class="tooltip -top-10 left-0">Bedrooms</span>
          </div>

          <div
            v-if="
              unit.internalAreaEstate &&
              unit.internalAreaEstate > 0 &&
              globals[0].isInternalAreaEstate
            "
            class="has-tooltip unit-spec-icon"
          >
            <TotalIntAreaIcon />
            <span class="font-semibold"
              >{{ unit.internalAreaEstate }}m&sup2;</span
            >
            <span class="tooltip -top-10 left-0">Internal Area</span>
          </div>

          <div
            v-if="
              unit.bathrooms && unit.bathrooms > 0 && globals[0].isBathroomsCard
            "
            class="has-tooltip unit-spec-icon"
          >
            <BathroomIcon />
            <span class="font-semibold">{{ unit.bathrooms }}</span>
            <span class="tooltip -top-10 left-0">Bathrooms</span>
          </div>

          <div
            v-if="unit.balcony && unit.balcony > 0 && globals[0].isBalcony"
            class="has-tooltip unit-spec-icon"
          >
            <BalconyIcon />
            <span class="font-semibold">{{ unit.balcony }}m&sup2;</span>
            <span class="tooltip -top-10 left-0">Balcony</span>
          </div>

          <div
            v-if="
              unit.coveredPatio &&
              unit.coveredPatio > 0 &&
              globals[0].isCoveredPatio
            "
            class="has-tooltip unit-spec-icon"
          >
            <CoverPatioIcon />
            <span class="font-semibold">{{ unit.coveredPatio }}m&sup2;</span>
            <span class="tooltip -top-10 left-0">Patio</span>
          </div>

          <div
            v-if="unit.garden && unit.garden > 0 && globals[0].isGarden"
            class="has-tooltip unit-spec-icon"
          >
            <FlowerOutlineIcon />
            <span class="font-semibold">{{ unit.garden }}m&sup2;</span>
            <span class="tooltip -top-10 left-0">Garden</span>
          </div>

          <div
            v-if="unit.parking && unit.parking > 0 && globals[0].isParkingCard"
            class="has-tooltip unit-spec-icon"
          >
            <CarIcon />
            <span class="font-semibold">{{ unit.parking }}</span>
            <span class="tooltip -top-10 left-0">Parking Bays</span>
          </div>

          <div
            v-if="unit.garages && unit.garages > 0"
            class="has-tooltip unit-spec-icon"
          >
            <GarageIcon />
            <span class="flex justify-center font-semibold">
              {{ unit.garages }}
              <div v-html="globals[0].isGarageUnit"></div>
            </span>
            <span class="tooltip -top-10 left-0">Garages</span>
          </div>

          <div
            v-if="
              unit.totalAreaEstate &&
              unit.totalAreaEstate > 0 &&
              globals[0].isTotalAreaEstate
            "
            class="has-tooltip unit-spec-icon"
          >
            <TotalAreaIcon />
            <span class="font-semibold">{{ unit.totalAreaEstate }}m&sup2;</span>
            <span class="tooltip -top-10 -left-14">Total Area</span>
          </div>

          <div
            v-if="
              unit.storeroom && unit.storeroom > 0 && globals[0].isStoreroom
            "
            class="has-tooltip unit-spec-icon"
          >
            <StoreIcon />
            <span class="font-semibold">{{ unit.storeroom }}</span>
            <span class="tooltip -top-10 left-0">Storeroom</span>
          </div>

          <div
            v-if="
              unit.internalArea &&
              unit.internalArea > 0 &&
              globals[0].isInternalAreaCard
            "
            class="has-tooltip unit-spec-icon"
          >
            <HomeIcon />
            <span class="font-semibold">{{ unit.internalArea }}m&sup2;</span>
            <span class="tooltip -top-10 left-0">Home Size</span>
          </div>

          <div
            v-if="
              unit.externalArea &&
              unit.externalArea > 0 &&
              globals[0].isExternalAreaCard
            "
            class="has-tooltip unit-spec-icon"
          >
            <StandIcon />
            <span class="font-semibold">{{ unit.externalArea }}m&sup2;</span>
            <span class="tooltip -top-10 -left-14">Stand Size</span>
          </div>

          <div
            v-if="unit.isAircon && unit.isAircon === true"
            class="has-tooltip unit-spec-icon"
          >
            <SnowflakeIcon />
            <span class="font-semibold">Yes</span>
            <span class="tooltip -top-10 -left-20">Aircon</span>
          </div>
        </div>

        <!-- View Content -->
        <div class="flex flex-row mt-5">
          <div class="w-2/4">
            <button
              v-if="unit.customIdViewButton"
              class="bg-surface w-full items-center px-4 py-2 text-primary-inverse text-md font-semibold uppercase tracking-wider duration-150 hover:bg-surface-600"
              v-on:click="
                $emit('update:unit-dialog-data', { open: true, data: unit })
              "
              :id="unit.customIdViewButton"
            >
              More Info
            </button>

            <button
              v-else
              class="bg-surface w-full items-center px-4 py-2 text-primary-inverse text-md font-semibold uppercase tracking-wider duration-150 hover:bg-surface-600"
              v-on:click="
                $emit('update:unit-dialog-data', { open: true, data: unit })
              "
            >
              More Info
            </button>
          </div>

          <!-- Unit Status Content -->
          <div class="w-2/4" v-if="globals[0].isGlobalOverrideAction === true">
            <a :href="globals[0].globalOverrideAction" target="_blank">
              <button
                class="bg-primary hover:bg-primary-600 items-center w-full py-2 fontSizeBtn font-semibold uppercase tracking-wider text-primary-inverse"
              >
                {{ globals[0].globalOverrideActionButton }}
              </button>
            </a>
          </div>

          <div class="w-2/4" v-else>
            <div v-if="unit.isOverrideAction === true">
              <a :href="unit.unitOverrideAction" target="_blank">
                <button
                  class="bg-primary hover:bg-primary-600 items-center w-full px-4 py-2 text-md font-semibold uppercase tracking-wider text-primary-inverse"
                >
                  {{ unit.unitOverrideActionButton }}
                </button>
              </a>
            </div>

            <div class="has-tooltip" v-else-if="globals[0].allowReserve">
              <button
                disabled
                class="bg-surface-500 w-full items-center px-4 py-2 text-md font-semibold uppercase tracking-wider cursor-not-allowed text-primary-inverse"
                v-if="
                  unit.status === 'pending' ||
                  unit.status === 'pendingUnitDetails' ||
                  unit.status === 'pendingPayment'
                "
              >
                Pending
              </button>
              <button
                disabled
                class="bg-surface-500 w-full items-center px-4 py-2 text-md font-semibold uppercase tracking-wider cursor-not-allowed text-primary-inverse"
                v-if="unit.status === 'reserved'"
              >
                Reserved
              </button>

              <button
                disabled
                class="bg-surface-500 w-full items-center px-4 py-2 text-md font-semibold uppercase tracking-wider cursor-not-allowed text-primary-inverse"
                v-if="unit.status === 'sold'"
              >
                Sold
              </button>

              <div>
                <button
                  class="relative bg-surface-400 items-center w-full px-4 py-2 text-primary-inverse tracking-wider duration-150"
                  v-if="unit.status === 'available' && disableReserve"
                >
                  <span class="text-md font-semibold uppercase">Reserve</span>
                  <span class="tooltip -bottom-0 -right-0"
                    >This reserve button will become active once sales go
                    live</span
                  >
                </button>

                <button
                  :disabled="isLoading"
                  class="items-center w-full px-4 py-2 text-primary-inverse text-md font-semibold uppercase tracking-wider duration-150 bg-primary hover:bg-primary-600"
                  @click="unitReserve(unit.name, unit.id)"
                  v-else-if="
                    unit.status === 'available' &&
                    !disableReserve &&
                    !isPendingBlocked
                  "
                >
                  <span v-if="isLoading && unit.id === unitIdOnClick">
                    <clipLoader :loading="isLoading" />
                  </span>
                  <span v-else>Reserve</span>
                </button>
              </div>

              <!--                  Max Reservable Units Button-->
              <button
                class="relative bg-surface-400 items-center w-full px-4 py-2 text-primary-inverse tracking-wider duration-150"
                v-if="
                  unit.status === 'available' &&
                  !disableReserve &&
                  isPendingBlocked
                "
              >
                <span class="text-md font-semibold uppercase">{{
                  globals[0].maxReservableUnitsHomeScreenButtons
                }}</span>
                <span class="tooltip -bottom-0 -right-0">{{
                  globals[0].maxReservableUnitsHomeScreenTooltip
                }}</span>
              </button>
            </div>

            <div v-else>
              <button
                class="bg-primary hover:bg-primary-600 items-center w-full px-4 py-2 text-md font-semibold uppercase tracking-wider text-primary-inverse"
                @click.prevent="openContactDialog(unit.name, unit.standNumber)"
              >
                ENQUIRE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="user.profile.unitShortlist.length === 0"
      class="flex flex-col items-center mt-5"
    >
      <p class="mb-2">Hit the</p>
      <div class="starIconCss text-primary" id="starIcon">
        <ShortlistIcon :size="40" />
      </div>
      <p class="mt-2">button to add homes to your Shortlist</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APP_TITLE } from "@/constants.js";
import UnitPrice from "../shared/UnitPrice.vue";
import AddToShortlist from "./AddToShortlist.vue";
import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BalconyIcon from "vue-material-design-icons/Balcony.vue";
import CoverPatioIcon from "vue-material-design-icons/GarageOpenVariant.vue";
import FlowerOutlineIcon from "vue-material-design-icons/FlowerOutline.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import StandIcon from "vue-material-design-icons/ArrowExpandAll.vue";
import HomeIcon from "vue-material-design-icons/Home.vue";
import GarageIcon from "vue-material-design-icons/GarageVariant.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";
import { profilesCollection, unitsCollection } from "@/firebaseCompat.js";
import firebase from "firebase/compat/app";
import ShortlistIcon from "vue-material-design-icons/Star.vue";
import clipLoader from "../shared/ClipLoader.vue";
import StoreIcon from "vue-material-design-icons/Store.vue";
import PendingOrReserveOrSoldLayout from "@/components/app/PendingOrReserveOrSoldLayout.vue";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "MainUnitReserveListShortlist",
  components: {
    PendingOrReserveOrSoldLayout,
    TotalIntAreaIcon,
    TotalAreaIcon,
    StoreIcon,
    UnitPrice,
    AddToShortlist,
    BathroomIcon,
    BalconyIcon,
    CoverPatioIcon,
    FlowerOutlineIcon,
    BedroomIcon,
    CarIcon,
    StandIcon,
    HomeIcon,
    GarageIcon,
    SnowflakeIcon,
    ShortlistIcon,
    clipLoader,
  },
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      default: [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data() {
    return {
      unitIdOnClick: "",
      isLoading: false,
      unitsProfile: [],
      deletedShortlistItems: [],
      title: APP_TITLE,
      now: new Date(),
      initialAmount: 50,
      addedAmount: 150,
      roleStatuses: ["admin", "admin-viewonly", "superadmin"],
    };
  },
  methods: {
    cutAfterHyphen(inputString) {
      if (inputString.includes("-")) {
        var parts = inputString.split("-");
        return parts.length > 1 ? parts[1] : inputString;
      } else {
        return inputString;
      }
    },
    handleScroll() {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 250
      ) {
        this.addToUnitsTotal();
      }
    },
    addToUnitsTotal() {
      this.initialAmount = this.initialAmount + this.addedAmount;
    },
    updateNow() {
      this.now = Date.now();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    unitReserve(unitName, unitId) {
      this.unitIdOnClick = unitId;
      this.isLoading = true;

      unitsCollection
        .doc(unitId)
        .update({
          pendingCountDownTimerStart: firebase.firestore.Timestamp.now(),
          pendingCountDownTimerExpires: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.$router.push({
            name: "reserve",
            params: { name: unitName },
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    openContactDialog(unitName, unitErf) {
      this.emitter.emit("update:unit-contact-data", {
        open: true,
        unitName,
        unitErf,
      });
    },
    getUnits() {
      this.unitsProfile = [];
      const unitIDs = this.user.profile.unitShortlistIDs;

      // Split the unitIDs into chunks of 10.
      const chunkSize = 10;
      for (let i = 0; i < unitIDs.length; i += chunkSize) {
        this.test();
      }
    },
    async test() {
      let items = this.units;

      Object.entries(items);

      const result = Object.keys(items).map((key) => {
        if (items[key]) {
          return items[key];
        }
      });

      const unitIDs = this.user.profile.unitShortlistIDs;

      unitIDs.filter((value) => {
        if (value) {
          let i = 0;
          while (i < result.length) {
            if (result[i].id === value) {
              let item = result[i];
              item["id"] = result[i].id;

              // If the user has deleted this unit without closing the
              // menu, don't add it ever again.
              if (
                this.deletedShortlistItems.find(
                  (x) => x.unitName === result[i].name,
                )
              ) {
                // Do nothing as the unit should not be put back.
              } else {
                // If the unit has not yet been added to
                // this.units, add it.
                if (!this.unitsProfile.some((e) => e.id === result[i].id)) {
                  this.unitsProfile.push(item);
                } else {
                  // The unit has already been added to this.units, but
                  // the status of the unit might have changed.
                  // For this reason, we need to find this unit within this.units,
                  // remove the existing unit in the list, and readd it...
                  // so that the new status of the unit will reflect.

                  // Find index.
                  const itemId = result[i].id;
                  const index = this.unitsProfile.findIndex((object) => {
                    return object.id === itemId;
                  });

                  // If the status differs, remove the unit and readd it
                  // with the new status.
                  if (this.unitsProfile[index].status !== result[i].status) {
                    this.unitsProfile.splice(index, 1);
                    this.unitsProfile.push(item);
                  } else if (
                    this.unitsProfile[index].prearranged !==
                    result[i].prearranged
                  ) {
                    this.unitsProfile.splice(index, 1);
                    this.unitsProfile.push(item);
                  }
                }
              }
            }
            i++;
          }
        }
      });
    },

    deleteFromShortlist(unitID, unitName) {
      const shortlistData = {
        unitID: unitID,
        unitName: unitName,
      };

      // Taking into consideration the fact that the user
      // might not close the menu while the status of a unit changes,
      // we need to ensure that we do not readd the unit to the shortlist
      // in such rare instances where the user has deleted the unit
      // from his or her shortlist.
      this.deletedShortlistItems.push(shortlistData);

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
          unitShortlist:
            firebase.firestore.FieldValue.arrayRemove(shortlistData),
        })
        .then(() => {
          this.addToast(SEVERITY.SUCCESS, {
            detail: "Unit removed from your shortlist.",
          });
        })
        .catch(({ message: detail }) => {
          this.addToast(SEVERITY.ERROR, { detail });
        });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
      launchDateMilliseconds: "launchDateMilliseconds",
      isPendingBlocked: "isPendingBlocked",
    }),
    totalUnits: function () {
      return this.user.profile.unitShortlist.length;
    },
    disableReserve: function () {
      if (this.msTillLaunchTime >= 0) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.globals[0].bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    totalUnits: function () {
      this.getUnits();
    },
  },
  async mounted() {
    await this.getUnits();
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style lang="css" scoped>
#starIcon {
  color: var(--color);
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.card-view-container {
  max-width: fit-content;
}

.image-container {
  width: 24rem;
  min-height: 15rem;
  object-fit: cover;
}

.unit-spec-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}

.unit-spec-icon {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0.45rem;
  text-align: center;
}

.unit-view-text {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.fontSizeBtn {
  font-size: 16px;
}

@media only screen and (max-width: 415px) {
  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }

  .unit-spec-icon {
    padding: 0 0.4rem;
  }
}

@media only screen and (max-width: 425px) {
  .cardMargin {
    margin: 0 1rem 1.5rem 1rem;
  }

  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }

  .unit-spec-icon {
    padding: 0 0.45rem;
  }
}

@media only screen and (max-width: 768px) {
  .unit-spec-icons-container {
    padding: 0 1rem;
  }

  .unit-spec-icon {
    padding: 0 0.2rem;
  }
}

@media only screen and (max-width: 375px) {
  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }

  .unit-spec-icon {
    padding: 0 0.3rem;
  }
}

@media only screen and (max-width: 320px) {
  .image-container {
    width: 24rem;
  }
}
</style>
