import { STATUS } from "../status.js";
import { settingsCollection } from "@/firebaseCompat.js";
import { SETTINGS_DOCUMENTS } from "@/constants/index.js";
import { ERRORS } from "@/constants/index.js";
import firebase from "firebase/compat/app";
import { useUserStore } from "../user";

export const actions = {
  async listenToGlobalSettings() {
    try {
      this.status[SETTINGS_DOCUMENTS.GLOBAL_SETTINGS].status = STATUS.PENDING;

      this.status[SETTINGS_DOCUMENTS.GLOBAL_SETTINGS].stopListening =
        settingsCollection
          .doc(SETTINGS_DOCUMENTS.GLOBAL_SETTINGS)
          .onSnapshot((doc) => {
            const globalSettings = doc.data();

            if (!doc.exists || globalSettings === undefined) {
              throw new Error(ERRORS.GLOBAL_SETTINGS_NOT_FOUND);
            }

            this.settings.globalSettings = globalSettings;
          });

      this.status[SETTINGS_DOCUMENTS.GLOBAL_SETTINGS].status = STATUS.LISTENING;
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.GLOBAL_SETTINGS].status = STATUS.REJECTED;
      throw error;
    }
  },
  async updateGlobalSettings(settings) {
    try {
      const modified = firebase.firestore.FieldValue.serverTimestamp();
      const { uid: modifiedBy } = useUserStore();
      await settingsCollection.doc(SETTINGS_DOCUMENTS.GLOBAL_SETTINGS).update({
        ...settings,
        modified,
        modifiedBy,
      });
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.GLOBAL_SETTINGS].status = STATUS.REJECTED;
      throw error;
    }
  },
  async listenToTheme() {
    try {
      this.status[SETTINGS_DOCUMENTS.THEME].status = STATUS.PENDING;

      this.status[SETTINGS_DOCUMENTS.THEME].stopListening = settingsCollection
        .doc(SETTINGS_DOCUMENTS.THEME)
        .onSnapshot((doc) => {
          if (
            this.status[SETTINGS_DOCUMENTS.THEME].status !== STATUS.LISTENING
          ) {
            this.status[SETTINGS_DOCUMENTS.THEME].status = STATUS.LISTENING;
          }

          if (!doc.exists) return;

          const theme = doc.data();
          if (theme === undefined) {
            throw new Error(ERRORS.THEME_SETTINGS_MALFORMED);
          }

          this.settings.theme = theme;
        });
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.THEME].status = STATUS.REJECTED;
      throw error;
    }
  },
  async listenToAccountSettings() {
    try {
      this.status[SETTINGS_DOCUMENTS.ACCOUNT].status = STATUS.PENDING;

      this.status[SETTINGS_DOCUMENTS.ACCOUNT].stopListening = settingsCollection
        .doc(SETTINGS_DOCUMENTS.ACCOUNT)
        .onSnapshot((doc) => {
          if (
            this.status[SETTINGS_DOCUMENTS.ACCOUNT].status !== STATUS.LISTENING
          ) {
            this.status[SETTINGS_DOCUMENTS.ACCOUNT].status = STATUS.LISTENING;
          }

          if (!doc.exists) return;

          const account = doc.data();
          if (account === undefined) {
            throw new Error(ERRORS.ACCOUNT_SETTINGS_MALFORMED);
          }

          this.settings[SETTINGS_DOCUMENTS.ACCOUNT] = account;
          this.settings[SETTINGS_DOCUMENTS.ACCOUNT] = account;
        });
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.ACCOUNT].status = STATUS.REJECTED;
      throw error;
    }
  },
  async updateDomainSettings(domain) {
    try {
      await settingsCollection.doc(SETTINGS_DOCUMENTS.ACCOUNT).set(
        {
          domain,
        },
        { merge: true },
      );
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.ACCOUNT].status = STATUS.REJECTED;
      throw error;
    }
  },
  async updateDialCodeSettings(dialCode) {
    try {
      await settingsCollection.doc(SETTINGS_DOCUMENTS.ACCOUNT).set(
        {
          dialCode,
        },
        { merge: true },
      );
    } catch (error) {
      this.status[SETTINGS_DOCUMENTS.ACCOUNT].status = STATUS.REJECTED;
      throw error;
    }
  },
};
