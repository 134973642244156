import { ref } from "vue";
import { useDocument } from "vuefire";
import { settingsRef } from "@/firebase.js";
import { doc } from "firebase/firestore";
import { SETTINGS_DOCUMENTS } from "@/constants/index.js";

let globalSettings = ref(null);
let isLoadingGlobalSettings = ref(true);

let theme = ref(null);
let isLoadingTheme = ref(true);

let initialized = false;

export function firestoreStore() {
  if (!initialized) {
    const { pending: pendingGlobalSettings } = useDocument(
      doc(settingsRef, SETTINGS_DOCUMENTS.GLOBAL_SETTINGS),
      {
        target: globalSettings,
      },
    );
    isLoadingGlobalSettings.value = pendingGlobalSettings;

    const { pending: pendingTheme } = useDocument(
      doc(settingsRef, SETTINGS_DOCUMENTS.THEME),
      {
        target: theme,
      },
    );
    isLoadingTheme.value = pendingTheme;

    initialized = true;
  }

  return { globalSettings, isLoadingGlobalSettings, theme, isLoadingTheme };
}
