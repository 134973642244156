import tinycolor from "tinycolor2";

export const adjustShade = (color, adjustment) => {
  if (adjustment === 0 || adjustment < -100 || adjustment > 100)
    return tinycolor(color);
  if (adjustment < 0) {
    return tinycolor(color).lighten(-adjustment);
  } else {
    return tinycolor(color).darken(adjustment);
  }
};
