import { defineStore } from "pinia";
import { state } from "./state.js";
import { actions } from "./actions.js";
import { getters } from "./getters.js";

export const useSettingsStore = defineStore("settings", {
  state,
  actions,
  getters,
});
