<template>
  <div>
    <button
      v-if="!isUnitInShortlist"
      @click="addToShortlist(unitID)"
      class="has-tooltip flex items-center justify-center w-full p-2 text-sm tracking-wider font-semibold shadow-border bg-surface-100 rounded hover:bg-surface-600 hover:text-white"
    >
      <span>
        <div id="starIcon text-primary" class="flex">
          <ShortlistIcon />
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <span class="is-bold numberContainer">
              {{ shortListCount }}
            </span>
          </div>
        </div>
        <div v-if="shortListCount >= globals[0].shortlistUnitShow">
          <p class="span_paragraph"></p>
        </div>
      </span>
    </button>

    <button
      v-else
      @click="deleteFromShortlist(unitID, unitName)"
      class="bg-surface has-tooltip flex items-center justify-center p-2 w-full text-sm tracking-wider font-semibold text-white shadow-border rounded"
    >
      <span>
        <div id="starIcon text-primary" class="flex">
          <ShortlistIcon />
          <div v-if="shortListCount >= globals[0].shortlistUnitShow">
            <span class="is-bold numberContainer">
              {{ shortListCount - 1 }}
            </span>
          </div>
        </div>
        <div v-if="shortListCount >= globals[0].shortlistUnitShow">
          <p class="span_paragraph"></p>
        </div>
      </span>
    </button>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { mapGetters } from "vuex";
import { profilesCollection, unitsCollection } from "@/firebaseCompat.js";
import { isNil } from "lodash-es";
import { indexOf } from "lodash-es";
import ShortlistIcon from "vue-material-design-icons/Star.vue";
import axios from "axios";
import { NOTIFY_SHORTLIST_URL } from "@/constants.js";
import { usePrimeToast, SEVERITY } from "@/utils";

export default {
  name: "AddToShortlist",
  components: {
    ShortlistIcon,
  },
  props: {
    unit: {
      default: () => [],
    },
    unitID: {
      default: null,
    },
    unitName: {
      type: String,
    },
    shortListCount: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup: () => ({
    addToast: usePrimeToast(),
  }),
  data: () => ({
    unitShortlistUser: NOTIFY_SHORTLIST_URL,
  }),
  methods: {
    sendEmail() {
      axios
        .post(this.unitShortlistUser, {
          unit_name: this.unitName,
          unit_erf: this.unit.standNumber,
          first_name: this.user.profile.firstName,
          last_name: this.user.profile.lastName,
          email: this.user.profile.email,
          phone: this.user.profile.e164ContactNumber,
        })
        .then(() => {
          // Do nothing...)
        })
        .catch((error) => {
          console.log(error, "Error sending email");
        });
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    addToShortlist(unitID) {
      if (isNil(this.user) || !this.user.loggedIn) {
        this.$router.push("/login");
        this.addToast(SEVERITY.ERROR, {
          detail:
            "Please create an account and sign in to add a unit to your shortlist.",
        });

        return;
      }

      const shortlistIncrementUnit = {
        shortlistIncrement: firebase.firestore.FieldValue.increment(1),
        unitID: unitID,
      };
      const firstDocRefUnit = unitsCollection.doc(unitID);
      const existDocUnit = firstDocRefUnit.get();
      existDocUnit.then((resDoc) => {
        if (resDoc.exists) {
          firstDocRefUnit.update(shortlistIncrementUnit);
        } else {
          firstDocRefUnit.set(shortlistIncrementUnit);
        }
      });

      const shortlistData = {
        unitID: unitID,
        unitName: this.unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayUnion(unitID),
          unitShortlist:
            firebase.firestore.FieldValue.arrayUnion(shortlistData),
        })
        .then(() => {
          this.sendEmail();
          this.addToast(SEVERITY.SUCCESS, {
            detail: "Unit added to your shortlist.",
          });
        })
        .catch(({ message: detail }) => {
          this.addToast(SEVERITY.ERROR, { detail });
        });
    },
    deleteFromShortlist(unitID, unitName) {
      const shortlistData = {
        unitID: unitID,
        unitName: unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
          unitShortlist:
            firebase.firestore.FieldValue.arrayRemove(shortlistData),
        })
        .then(() => {
          let shortlistIncrementUnit = {
            shortlistIncrement: firebase.firestore.FieldValue.increment(-1),
            unitID: unitID,
          };
          unitsCollection.doc(unitID).update(shortlistIncrementUnit);

          this.addToast(SEVERITY.SUCCESS, {
            detail: "Unit removed from your shortlist.",
          });
        })
        .catch(({ message: detail }) => {
          this.addToast(SEVERITY.ERROR, { detail });
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
    }),
    isUnitInShortlist() {
      if (
        !isNil(this.user) &&
        !isNil(this.user.profile) &&
        !isNil(this.user.profile.unitShortlistIDs)
      ) {
        return indexOf(this.user.profile.unitShortlistIDs, this.unitID) >= 0;
      }

      return false;
    },
  },
};
</script>
<style lang="css" scoped>
#starIcon {
  color: var(--color);
}

.is-bold {
  font-weight: bold;
}

.numberContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  margin-top: -5px;
  font-size: 10px;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.span_paragraph {
  font-size: 10px;
}

.is-bold {
  font-weight: bold;
}
</style>
