import { STATUS } from "../status.js";
import { SETTINGS_DOCUMENTS } from "@/constants/index.js";

export const state = () => ({
  settings: Object.values(SETTINGS_DOCUMENTS).reduce(
    (settings, document) => ({
      ...settings,
      [document]: undefined,
    }),
    {},
  ),
  status: Object.values(SETTINGS_DOCUMENTS).reduce(
    (documentStatuses, document) => ({
      ...documentStatuses,
      [document]: {
        status: STATUS.IDLE,
        stopListening: undefined,
      },
    }),
    {},
  ),
});
