<template>
  <div class="px-1 md:px-5 pb-3">
    <!-- Content -->
    <div class="tableDivCss">
      <!-- Table Content -->
      <table class="w-full sm:bg-white rounded overflow-hidden sm:shadow-lg">
        <thead
          class="text-surface-800 rounded-l-lg sm:rounded-none mb-2 sm:mb-0 bg-surface-300"
        >
          <!-- Table Header Content -->
          <th
            v-if="settings.isStandNo && settings.isStandNoListView"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('standNumber')"
            :class="{ sorting: sortField === 'standNumber' }"
          >
            {{ settings.standNumberName }}
            <MenuDown
              v-if="isSorted('standNumber', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('standNumber', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            class="heading-text"
            @click="updateSorting('name')"
            :class="{ sorting: sortField === 'name' }"
          >
            <span>
              {{
                settings.dynamicFieldAddress !== null
                  ? settings.dynamicFieldAddress
                  : "Address"
              }}
            </span>
            <MenuDown
              v-if="isSorted('name', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('name', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isPrice"
            class="heading-text"
            @click="updateSorting('price')"
            :class="{ sorting: sortField === 'price' }"
          >
            Price
            <MenuDown
              v-if="isSorted('price', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('price', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isFloors"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('floor')"
            :class="{ sorting: sortField === 'floor' }"
          >
            Description
            <MenuDown
              v-if="isSorted('floor', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('floor', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isInternalArea"
            class="heading-text"
            @click="updateSorting('internalArea')"
            :class="{ sorting: sortField === 'internalArea' }"
          >
            Home Size
            <MenuDown
              v-if="isSorted('internalArea', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('internalArea', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isExternalArea"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('externalArea')"
            :class="{ sorting: sortField === 'externalArea' }"
          >
            Stand Size
            <MenuDown
              v-if="isSorted('externalArea', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('externalArea', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isBedrooms"
            class="heading-text"
            @click="updateSorting('bedrooms')"
            :class="{ sorting: sortField === 'bedrooms' }"
            style="min-width: 55px"
          >
            Beds
            <MenuDown
              v-if="isSorted('bedrooms', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('bedrooms', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isBathrooms"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('bathrooms')"
            :class="{ sorting: sortField === 'bathrooms' }"
          >
            Baths
            <MenuDown
              v-if="isSorted('bathrooms', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('bathrooms', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isParking &&
              settings.estateListViewLayout === '2' &&
              !settings.isParkingNextToGarage
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('parking')"
            :class="{ sorting: sortField === 'parking' }"
          >
            Parking Bays
            <MenuDown
              v-if="isSorted('parking', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('parking', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isOutlook && settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('view')"
            :class="{ sorting: sortField === 'view' }"
          >
            {{ settings.outlookName }}
            <MenuDown
              v-if="isSorted('view', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('view', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isAspect && settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('aspectEstate')"
            :class="{ sorting: sortField === 'aspectEstate' }"
          >
            {{
              settings.aspectName !== null ? settings.aspectName : "Direction"
            }}
            <MenuDown
              v-if="isSorted('aspectEstate', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('aspectEstate', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isLevies && settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('levies')"
            :class="{ sorting: sortField === 'levies' }"
          >
            Levies
            <MenuDown
              v-if="isSorted('levies', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('levies', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isRates && settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('rates')"
            :class="{ sorting: sortField === 'rates' }"
          >
            Rates
            <MenuDown
              v-if="isSorted('rates', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('rates', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <!-- Expenses Hidden -->
          <th
            v-if="
              globals[0].isExpense1 &&
              globals[0].isExpense1List &&
              globals[0].estateListViewLayout === '2' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense1')"
            :class="{ sorting: sortField === 'expense1' }"
          >
            {{ globals[0].dynamicExpense1 }}
            <MenuDown
              v-if="isSorted('expense1', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense1', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              globals[0].isExpense2 &&
              globals[0].isExpense2List &&
              globals[0].estateListViewLayout === '2' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense2')"
            :class="{ sorting: sortField === 'expense2' }"
          >
            {{ globals[0].dynamicExpense2 }}
            <MenuDown
              v-if="isSorted('expense2', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense2', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              globals[0].isExpense3 &&
              globals[0].isExpense3List &&
              globals[0].estateListViewLayout === '2' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense3')"
            :class="{ sorting: sortField === 'expense3' }"
          >
            {{ globals[0].dynamicExpense3 }}
            <MenuDown
              v-if="isSorted('expense3', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense3', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('guaranteedRental')"
            :class="{ sorting: sortField === 'guaranteedRental' }"
          >
            <!--          Rental Income-->
            Est. Rental
            <MenuDown
              v-if="isSorted('guaranteedRental', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('guaranteedRental', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isBedsAndBaths && settings.estateListViewLayout === '2'
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('beds')"
            :class="{ sorting: sortField === 'beds' }"
          >
            Beds and Baths
            <MenuDown
              v-if="isSorted('beds', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('beds', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isInterior && settings.estateListViewLayout === '2'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('internalAreaEstate')"
            :class="{ sorting: sortField === 'internalAreaEstate' }"
          >
            Interior
            <MenuDown
              v-if="isSorted('internalAreaEstate', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('internalAreaEstate', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isBalconyList && settings.estateListViewLayout === '2'
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('balcony')"
            :class="{ sorting: sortField === 'balcony' }"
          >
            Balcony
            <MenuDown
              v-if="isSorted('balcony', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('balcony', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isCoveredPatioList &&
              settings.estateListViewLayout === '2'
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('coveredPatio')"
            :class="{ sorting: sortField === 'coveredPatio' }"
          >
            Patio
            <MenuDown
              v-if="isSorted('coveredPatio', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('coveredPatio', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isDynamicField"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('dynamicField')"
            :class="{ sorting: sortField === 'dynamicField' }"
          >
            {{ settings.dynamicField }}
            <MenuDown
              v-if="isSorted('dynamicField', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('dynamicField', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isGardenList && settings.estateListViewLayout === '2'
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('garden')"
            :class="{ sorting: sortField === 'garden' }"
          >
            Garden
            <MenuDown
              v-if="isSorted('garden', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('garden', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isGarageList"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('garages')"
            :class="{ sorting: sortField === 'garages' }"
          >
            <span> Garage </span>
            <MenuDown
              v-if="isSorted('garages', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('garages', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isParking &&
              (settings.estateListViewLayout === '2' ||
                settings.estateListViewLayout === '1') &&
              settings.isParkingNextToGarage
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('parking')"
            :class="{ sorting: sortField === 'parking' }"
          >
            Parking Bays
            <MenuDown
              v-if="isSorted('parking', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('parking', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isTotalAreaList && settings.estateListViewLayout === '2'
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('totalAreaEstate')"
            :class="{ sorting: sortField === 'totalAreaEstate' }"
          >
            Total Area
            <MenuDown
              v-if="isSorted('totalAreaEstate', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('totalAreaEstate', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isStoreroom && settings.estateListViewLayout === '1'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('storeroom')"
            :class="{ sorting: sortField === 'storeroom' }"
          >
            Storeroom
            <MenuDown
              v-if="isSorted('storeroom', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('storeroom', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isOutlook && settings.estateListViewLayout === '1'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('view')"
            :class="{ sorting: sortField === 'view' }"
          >
            {{ settings.outlookName }}
            <MenuDown
              v-if="isSorted('view', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('view', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              settings.isParking &&
              settings.estateListViewLayout === '1' &&
              !settings.isParkingNextToGarage
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('parking')"
            :class="{ sorting: sortField === 'parking' }"
          >
            Parking Bays
            <MenuDown
              v-if="isSorted('parking', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('parking', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isTotalArea && settings.estateListViewLayout === '1'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('totalArea')"
            :class="{ sorting: sortField === 'totalArea' }"
          >
            Apartment
            <MenuDown
              v-if="isSorted('totalArea', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('totalArea', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isLevies && settings.estateListViewLayout === '1'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('levies')"
            :class="{ sorting: sortField === 'levies' }"
          >
            Levies
            <MenuDown
              v-if="isSorted('levies', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('levies', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="settings.isRates && settings.estateListViewLayout === '1'"
            class="heading-text hidden md:table-cell"
            @click="updateSorting('rates')"
            :class="{ sorting: sortField === 'rates' }"
          >
            Rates
            <MenuDown
              v-if="isSorted('rates', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('rates', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <!-- Expenses Hidden -->
          <th
            v-if="
              globals[0].isExpense1 &&
              globals[0].isExpense1List &&
              globals[0].estateListViewLayout === '1' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense1')"
            :class="{ sorting: sortField === 'expense1' }"
          >
            {{ globals[0].dynamicExpense1 }}
            <MenuDown
              v-if="isSorted('expense1', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense1', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              globals[0].isExpense2 &&
              globals[0].isExpense2List &&
              globals[0].estateListViewLayout === '1' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense2')"
            :class="{ sorting: sortField === 'expense2' }"
          >
            {{ globals[0].dynamicExpense2 }}
            <MenuDown
              v-if="isSorted('expense2', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense2', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th
            v-if="
              globals[0].isExpense3 &&
              globals[0].isExpense3List &&
              globals[0].estateListViewLayout === '1' &&
              (globals[0].layoutStyle[0] === 'floor' ||
                globals[0].layoutStyle[1] === 'floor')
            "
            class="heading-text hidden md:table-cell"
            @click="updateSorting('expense3')"
            :class="{ sorting: sortField === 'expense3' }"
          >
            {{ globals[0].dynamicExpense3 }}
            <MenuDown
              v-if="isSorted('expense3', 'desc')"
              class="inline-block absolute top-3 -right-1"
            />
            <MenuUp
              v-if="isSorted('expense3', 'asc')"
              class="inline-block absolute top-3 -right-1"
            />
          </th>
          <th class="hidden md:table-cell heading-text-btn">Shortlist</th>
          <th class="heading-text-btn">Details</th>
          <th class="hidden md:table-cell heading-text-btn">Action</th>
        </thead>

        <tbody
          class="scroll"
          v-for="unit in filteredUnits.slice(0, this.initialAmount)"
          :key="unit.id"
        >
          <!-- Table Body Content -->
          <tr
            class="mb-2 sm:mb-0 bg-white"
            :class="[
              unit.status === 'reserved' ? 'reserved-list' : null,
              unit.status === 'sold' ? 'sold-list' : null,
            ]"
          >
            <!-- Stand Number Content -->
            <td
              v-if="settings.isStandNo && settings.isStandNoListView"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.standNumber }}
            </td>
            <!-- Name/Number Content -->
            <td
              class="truncate fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ removeLeadingZeros(cutAfterHyphen(unit.name)) }}
            </td>
            <!-- Price && Status Content -->
            <td
              v-if="windowWidth >= 750 && settings.isPrice"
              class="truncate fontSizeContainer body-item-container-estate"
              :class="{
                'p-3': user.loggedIn,
                'price-cta-correction':
                  !user.loggedIn || !user.profile.answeredDiscountSurvey,
                'hover:bg-surface-600': unit.status === 'sold',
                'hover:bg-surface-100': unit.status !== 'sold',
              }"
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                top: 0px;
                position: relative;
                overflow-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <UnitPrice
                class="relative"
                :unit="unit"
                :settings="settings"
                :show="true"
                :tableStyle="true"
                :bigPrice="false"
                :normalPrice="true"
              />
            </td>
            <td
              v-else-if="windowWidth < 750 && settings.isPrice"
              class="hover:bg-surface-100 truncate fontSizeContainer body-item-container-estate"
              :class="{
                'p-0': user.loggedIn,
                'price-cta-correction':
                  !user.loggedIn || !user.profile.answeredDiscountSurvey,
                'hover:bg-surface-600': unit.status === 'sold',
                'hover:bg-surface-100': unit.status !== 'sold',
              }"
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                top: 0px;
                position: relative;
                justify-content: center;
                align-content: center;
                text-align: center;
                overflow-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <UnitPrice
                class="relative"
                :unit="unit"
                :settings="settings"
                :show="true"
                :tableStyle="true"
                :bigPrice="false"
                :showWording="false"
                :normalPrice="true"
              />
            </td>
            <!-- Description Content -->
            <td
              v-if="settings.isFloors"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.floor }}
            </td>
            <!-- Home Size Content -->
            <td
              v-if="settings.isInternalArea"
              class="truncate fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.internalArea }}m&sup2;
            </td>
            <!-- Stand Size Content -->
            <td
              v-if="settings.isExternalArea"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.externalArea }}m&sup2;
            </td>
            <!-- Beds Content -->
            <td
              v-if="settings.isBedrooms"
              class="truncate fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.bedrooms }}
            </td>
            <!-- Baths Content -->
            <td
              v-if="settings.isBathrooms"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.bathrooms }}
            </td>
            <!-- Parking Bays Content -->
            <td
              v-if="
                settings.isParking &&
                settings.estateListViewLayout === '2' &&
                !settings.isParkingNextToGarage
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.parking }}
            </td>
            <!-- View Content -->
            <td
              v-if="settings.isOutlook && settings.estateListViewLayout === '2'"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.view }}
            </td>
            <!-- Unit Direction facing Content -->
            <!-- Direction facing === aspect -->
            <td
              v-if="settings.isAspect && settings.estateListViewLayout === '2'"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.aspectEstate }}
            </td>
            <!-- Levies Content -->
            <td
              v-if="settings.isLevies && settings.estateListViewLayout === '2'"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.levies && unit.levies > 0">
                {{ $filters.prettyCurrency(unit.levies.toFixed(0)) }}
              </span>

              <span v-else class="opacity-0 p-0"></span>
            </td>
            <!-- Rates Content -->
            <td
              v-if="settings.isRates && settings.estateListViewLayout === '2'"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.rates && unit.rates > 0">
                {{ $filters.prettyCurrency(unit.rates.toFixed(0)) }}
              </span>

              <span v-else class="opacity-0 p-0"></span>
            </td>
            <!-- Unit expense 1 Content -->
            <td
              v-if="
                globals[0].isExpense1 &&
                globals[0].isExpense1List &&
                globals[0].estateListViewLayout === '2' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense1 && unit.expense1 > 0">
                {{ $filters.prettyCurrency(unit.expense1) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 2 Content -->
            <td
              v-if="
                globals[0].isExpense2 &&
                globals[0].isExpense2List &&
                globals[0].estateListViewLayout === '2' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense2 && unit.expense2 > 0">
                {{ $filters.prettyCurrency(unit.expense2) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 3 Content -->
            <td
              v-if="
                globals[0].isExpense3 &&
                globals[0].isExpense3List &&
                globals[0].estateListViewLayout === '2' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense3 && unit.expense3 > 0">
                {{ $filters.prettyCurrency(unit.expense3) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit Rental Income Content -->
            <td
              v-if="
                settings.isEstRental && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.guaranteedRental && unit.guaranteedRental > 0">
                {{ $filters.prettyCurrency(unit.guaranteedRental.toFixed(0)) }}
              </span>

              <span
                v-else
                class="z-10 pt-2 pb-2 pr-5 pl-5 rounded"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
                NA
              </span>
            </td>
            <!-- Beds and Baths Content -->
            <td
              v-if="
                settings.isBedsAndBaths && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.beds }}
            </td>
            <!-- Interior Content -->
            <td
              v-if="
                settings.isInterior && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.internalAreaEstate }}m&sup2;
            </td>
            <!-- Interior Content -->
            <td
              v-if="
                settings.isBalconyList && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.balcony }}m&sup2;
            </td>
            <!-- Patio Content -->
            <td
              v-if="
                settings.isCoveredPatioList &&
                settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.coveredPatio }}m&sup2;
            </td>
            <!-- Unit View facing Content -->
            <td
              v-if="
                settings.isDynamicField && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              <span class="flex justify-center">
                {{ unit.dynamicField }}
                <div v-html="settings.isDynamicFieldUnit"></div>
              </span>
            </td>
            <!-- Garden Content -->
            <td
              v-if="
                settings.isGardenList && settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.garden }}m&sup2;
            </td>
            <!-- Garages Content -->
            <td
              v-if="settings.isGarageList"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span class="flex justify-center">
                {{ unit.garages }}
                <div v-html="settings.isGarageUnit"></div>
              </span>
            </td>
            <!-- Parking Bays Content -->
            <td
              v-if="
                settings.isParking &&
                (settings.estateListViewLayout === '2' ||
                  settings.estateListViewLayout === '1') &&
                settings.isParkingNextToGarage
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.parking }}
            </td>
            <!-- Total Area Estate Content -->
            <td
              v-if="
                settings.isTotalAreaList &&
                settings.estateListViewLayout === '2'
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.totalAreaEstate }}m&sup2;
            </td>
            <!-- Storeroom Content -->
            <td
              v-if="
                settings.isStoreroom && settings.estateListViewLayout === '1'
              "
              class="hover:bg-surface-100 p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.storeroom }}
            </td>

            <!-- View Content -->
            <td
              v-if="settings.isOutlook && settings.estateListViewLayout === '1'"
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container twoLineContainer"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="
                border-bottom: 2px solid rgba(243, 244, 246, 1);
                word-wrap: break-word;
                white-space: pre-wrap;
              "
            >
              {{ unit.view }}
            </td>
            <!-- Parking Bays Content -->
            <td
              v-if="
                settings.isParking &&
                settings.estateListViewLayout === '1' &&
                !settings.isParkingNextToGarage
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.parking }}
            </td>
            <!-- Apartment Content -->
            <td
              v-if="
                settings.isTotalArea && settings.estateListViewLayout === '1'
              "
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              {{ unit.totalArea }}m&sup2;
            </td>
            <!-- Levies Content -->
            <td
              v-if="settings.isLevies && settings.estateListViewLayout === '1'"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.levies && unit.levies > 0">
                {{ $filters.prettyCurrency(unit.levies.toFixed(0)) }}
              </span>

              <span v-else class="opacity-0 p-0"></span>
            </td>
            <!-- Rates Content -->
            <td
              v-if="settings.isRates && settings.estateListViewLayout === '1'"
              class="truncate hidden md:table-cell fontSizeContainer body-item-container-estate"
              :class="[
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100',
                windowWidth <= 750 ? 'p-0' : 'p-2',
              ]"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.rates && unit.rates > 0">
                {{ $filters.prettyCurrency(unit.rates.toFixed(0)) }}
              </span>

              <span v-else class="opacity-0 p-0"></span>
            </td>
            <!-- Unit expense 1 Content -->
            <td
              v-if="
                globals[0].isExpense1 &&
                globals[0].isExpense1List &&
                globals[0].estateListViewLayout === '1' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense1 && unit.expense1 > 0">
                {{ $filters.prettyCurrency(unit.expense1) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 2 Content -->
            <td
              v-if="
                globals[0].isExpense2 &&
                globals[0].isExpense2List &&
                globals[0].estateListViewLayout === '1' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense2 && unit.expense2 > 0">
                {{ $filters.prettyCurrency(unit.expense2) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <!-- Unit expense 3 Content -->
            <td
              v-if="
                globals[0].isExpense3 &&
                globals[0].isExpense3List &&
                globals[0].estateListViewLayout === '1' &&
                (globals[0].layoutStyle[0] === 'floor' ||
                  globals[0].layoutStyle[1] === 'floor')
              "
              class="p-2 truncate hidden md:table-cell fontSizeContainer body-item-container"
              :class="
                unit.status === 'sold'
                  ? 'hover:bg-surface-600'
                  : 'hover:bg-surface-100'
              "
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span v-if="unit.expense3 && unit.expense3 > 0">
                {{ $filters.prettyCurrency(unit.expense3) }}
              </span>

              <span
                v-else
                class="opacity-0 p-0"
                :class="
                  unit.status === 'sold'
                    ? 'hover:bg-surface-600'
                    : 'hover:bg-surface-100'
                "
              >
              </span>
            </td>
            <td
              class="cursor-pointer hidden md:table-cell fontSizeContainerBtn body-item-container-estate"
              :class="[windowWidth <= 750 ? 'p-0' : 'p-2']"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <span>
                <AddToShortlistTable
                  :unitID="unit.id"
                  :unitName="unit.name"
                  :show="false"
                  :unit="unit"
                  :shortListCount="unit.shortlistIncrement"
                />
              </span>
            </td>
            <!-- Unit View Content -->
            <td
              class="cursor-pointer body-item-container-estate"
              :class="[windowWidth <= 750 ? 'p-0' : 'p-2']"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <button
                v-if="unit.customIdViewButton"
                class="w-full text-white fontSizeContainerBtn md:fontSizeContainerBtn font-semibold uppercase tracking-wider bg-surface rounded duration-150 hover:bg-surface-600"
                :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
                :id="unit.customIdViewButton"
              >
                More Info
              </button>

              <button
                v-else
                class="w-full text-white fontSizeContainerBtn md:fontSizeContainerBtn font-semibold uppercase tracking-wider bg-surface rounded duration-150 hover:bg-surface-600"
                :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
              >
                More Info
              </button>
            </td>
            <!-- Unit Status Content -->
            <td
              class="cursor-pointer hidden md:table-cell body-item-container-estate"
              :class="[windowWidth <= 750 ? 'p-0' : 'p-2']"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
            >
              <div v-if="settings.isGlobalOverrideAction === true">
                <a :href="settings.globalOverrideAction" target="_blank">
                  <button
                    class="bg-primary hover:bg-primary-600 text-primary-inverse w-full items-center fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-pointer rounded"
                    :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                  >
                    {{ settings.globalOverrideActionButton }}
                  </button>
                </a>
              </div>

              <div v-else>
                <div v-if="unit.isOverrideAction === true">
                  <a :href="unit.unitOverrideAction" target="_blank">
                    <button
                      class="bg-primary hover:bg-primary-600 text-primary-inverse w-full items-center fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-pointer rounded"
                      :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                    >
                      {{ unit.unitOverrideActionButton }}
                    </button>
                  </a>
                </div>

                <div class="has-tooltip" v-else-if="settings.allowReserve">
                  <button
                    disabled
                    class="bg-surface-500 w-full items-center fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                    :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                    v-if="
                      unit.status === 'pending' ||
                      unit.status === 'pendingUnitDetails' ||
                      unit.status === 'pendingPayment'
                    "
                  >
                    Pending
                  </button>

                  <button
                    disabled
                    class="bg-surface-500 w-full items-center fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                    :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                    v-if="unit.status === 'reserved'"
                  >
                    Reserved
                  </button>

                  <button
                    disabled
                    class="bg-surface-500 w-full items-center fontSizeContainerBtn font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                    :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                    v-if="unit.status === 'sold'"
                  >
                    Sold
                  </button>

                  <div>
                    <button
                      class="relative bg-surface-400 rounded items-center w-full px-4 text-white fontSizeContainerBtn tracking-wider duration-150"
                      :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                      v-if="unit.status === 'available' && disableReserve"
                    >
                      <span class="text-md font-semibold uppercase">
                        Reserve
                      </span>
                      <span class="tooltip -bottom-0 -right-0"
                        >This reserve button will become active once sales go
                        live</span
                      >
                    </button>

                    <button
                      :disabled="isLoading"
                      :class="[
                        windowWidth <= 750 ? 'p-1' : 'p-2',
                        'w-full text-primary-inverse fontSizeContainerBtn font-semibold uppercase tracking-wider duration-150 rounded bg-primary hover:bg-primary-600',
                      ]"
                      @click="unitReserve(unit.name, unit.id)"
                      v-else-if="
                        unit.status === 'available' &&
                        !disableReserve &&
                        !isPendingBlocked
                      "
                    >
                      <span v-if="isLoading && unit.id === unitIdOnClick">
                        <clipLoader :loading="isLoading" />
                      </span>
                      <span v-else>Reserve</span>
                    </button>

                    <!--                  Max Reservable Units Button-->
                    <button
                      class="relative bg-surface-400 rounded items-center w-full px-4 py-2 text-white fontSizeContainerBtn tracking-wider duration-150"
                      v-if="
                        unit.status === 'available' &&
                        !disableReserve &&
                        isPendingBlocked
                      "
                    >
                      <span class="text-md font-semibold uppercase">
                        {{ settings.maxReservableUnitsHomeScreenButtons }}
                      </span>
                      <span class="tooltip -bottom-0 -right-0">
                        {{ settings.maxReservableUnitsHomeScreenTooltip }}
                      </span>
                    </button>
                  </div>
                </div>

                <div v-else>
                  <button
                    class="bg-primary w-full fontSizeContainerBtn font-semibold uppercase tracking-wider duration-150 hover:bg-primary-600 rounded text-primary-inverse"
                    :class="[windowWidth <= 750 ? 'p-1' : 'p-2']"
                    @click.prevent="
                      openContactDialog(unit.name, unit.standNumber)
                    "
                  >
                    ENQUIRE
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Content -->
    <div
      class="table-footer md:hidden flex flex-row items-center justify-center pb-1"
      style="box-shadow: 0 -5px 5px -5px #ccc"
    >
      <div
        class="uppercase font-semibold mx-1 fontSizeContainer cursor-pointer"
        :class="{ active: status === 'all' }"
        @click="$emit('update-status', 'all')"
      >
        Show All <span class="text-surface-400">{{ total }}</span>
      </div>
      <div
        class="uppercase font-semibold mx-1 fontSizeContainer cursor-pointer"
        :class="{ active: status === 'available-only' }"
        @click="$emit('update-status', 'available-only')"
      >
        Available Only <span class="text-surface-400">{{ availableOnly }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UnitPrice from "../shared/UnitPrice.vue";
import { mapGetters } from "vuex";
import AddToShortlistTable from "./AddToShortlistTable.vue";
import MenuDown from "vue-material-design-icons/MenuDown.vue";
import MenuUp from "vue-material-design-icons/MenuUp.vue";
import { APP_TITLE, APP_ADMIN_PAGINATION } from "@/constants.js";
import { unitsCollection } from "@/firebaseCompat.js";
import firebase from "firebase/compat/app";
import clipLoader from "../shared/ClipLoader.vue";
import { sort } from "@/mixins/helpers.js";

export default {
  name: "MainUnitReserveListTable",
  mixins: [sort],
  components: {
    AddToShortlistTable,
    UnitPrice,
    MenuDown,
    MenuUp,
    clipLoader,
  },
  props: {
    msTillLaunchTime: {
      type: Number,
    },
    units: {
      default: [],
    },
    status: {
      type: String,
    },
    total: {
      type: Number,
    },
    availableOnly: {
      type: Number,
    },
    guaranteedRentals: {
      type: Number,
    },
    settings: {
      type: Object,
    },
    sortField: {
      type: String,
    },
    sortDirection: {
      type: String,
    },
    unitDialogData: {
      type: Object,
    },
  },
  data() {
    return {
      unitIdOnClick: "",
      isLoading: false,
      currentSort: "name",
      currentSortDir: "asc",
      pageSize: APP_ADMIN_PAGINATION,
      currentPage: 1,
      title: APP_TITLE,
      windowWidth: window.innerWidth,
      now: new Date(),
      initialAmount: 50,
      addedAmount: 150,
      roleStatuses: ["admin", "admin-viewonly", "superadmin"],
    };
  },
  methods: {
    cutAfterHyphen(inputString) {
      if (inputString.includes("-")) {
        var parts = inputString.split("-");
        return parts.length > 1 ? parts[1] : inputString;
      } else {
        return inputString;
      }
    },
    handleScroll() {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 250
      ) {
        this.addToUnitsTotal();
      }
    },
    addToUnitsTotal() {
      this.initialAmount = this.initialAmount + this.addedAmount;
    },
    isSorted(field, direction) {
      if (this.sortField === field && this.localSortDirection === direction) {
        return true;
      }
      return false;
    },
    updateSorting(field) {
      if (this.sortField === field && this.localSortDirection === "asc") {
        this.$emit("update-sorting", { field: field, direction: "desc" });
      } else {
        this.$emit("update-sorting", { field: field, direction: "asc" });
      }
    },

    updateNow() {
      this.now = Date.now();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    unitReserve(unitName, unitId) {
      this.unitIdOnClick = unitId;
      this.isLoading = true;

      unitsCollection
        .doc(unitId)
        .update({
          pendingCountDownTimerStart: firebase.firestore.Timestamp.now(),
          pendingCountDownTimerExpires: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.$router.push({
            name: "reserve",
            params: { name: unitName },
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    openContactDialog(unitName, unitErf) {
      this.emitter.emit("update:unit-contact-data", {
        open: true,
        unitName,
        unitErf,
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    localSortDirection: {
      get() {
        return this.sortDirection;
      },
      set(newValue) {
        this.$emit("update:sortDirection", newValue);
      },
    },
    ...mapGetters({
      user: "user",
      launchDateMilliseconds: "launchDateMilliseconds",
      isPendingBlocked: "isPendingBlocked",
      globals: "globals",
    }),
    filteredUnits: function () {
      return this.mixinFilteredUnits;
    },
    disableReserve: function () {
      if (this.msTillLaunchTime >= 0) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.settings.bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.fontSizeContainer {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.fontSizeContainerBtn {
  font-size: 0.875rem;
  line-height: 1.6rem;
}

.twoLineContainer {
  max-width: 110px !important;
}

.priceContainer {
  font-size: 14px;
}

.table-footer {
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #fff;
}

.tableDivCss {
  width: 100%;
  overflow-x: auto;
}

.table-footer .active {
  border-bottom: 2px solid #aa9461;
}

.price-cta-correction {
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 15px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

thead tr:not(:first-child) {
  display: none;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text-noHover {
  position: relative;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text:hover {
  opacity: 0.7;
  background: #ffffff33;
}

.scroll {
  overflow-x: scroll;
}

.body-item-container-estate {
  line-height: 1rem;
  text-align: center;
}

.heading-text-btn {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

.reserved-list {
  background: #6c727f;
}

.sold-list {
  background: #3e404c;
  color: #fff;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }
}

@media (min-width: 768px) {
  .heading-text,
  .heading-text-noHover {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .heading-text-btn {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (max-width: 425px) {
  .price-cta-correction {
    padding-top: 8px;
    padding-bottom: 15px;
  }
}
</style>
