<template>
  <div class="grid grid-cols-2 gap-1">
    <p-dropdown
      :model-value="selectedCountry"
      v-on:update:modelValue="handleSelectCountry"
      :options="countries"
      filter
      optionLabel="name"
      placeholder="Select a Country"
      class="w-full border border-surface-400 rounded-r outline-none focus:border-primary focus:ring-primary"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex flex-row items-end">
          <img
            :alt="slotProps.value.label"
            src="@/assets/flags/flag_placeholder.png"
            :class="`mr-2 flag flag-${slotProps.value.code.toLowerCase()}`"
          />
          <div>{{ slotProps.value.name }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="flex items-end">
          <img
            :alt="slotProps.option.label"
            src="@/assets/flags/flag_placeholder.png"
            :class="`mr-2 flag flag-${slotProps.option.code.toLowerCase()}`"
          />
          <div>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </p-dropdown>
    <div>
      <div class="flex items-center relative">
        <span class="absolute left-2 opacity-30">{{
          `+${selectedCountry.dialCode}`
        }}</span>
        <input
          v-model="userPhoneNumber"
          @input="updatePhoneNumber"
          id="input-outline"
          type="number"
          class="appearance-none w-full pr-3 py-2 border border-surface-400 rounded outline-none focus:border-primary focus:ring-primary pl-12"
          placeholder=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { COUNTRIES, SOUTH_AFRICA_DIALCODE } from "@/constants/index.js";
import { mapState } from "pinia";
import { useSettingsStore } from "@/state";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import "@/assets/flags/flags.css";

const SELECTED_COUNTRY = COUNTRIES.find(
  ({ dialCode }) => dialCode === SOUTH_AFRICA_DIALCODE,
);

export default {
  name: "PhoneInput",
  props: {
    e164ContactNumber: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    initialised: false,
    countries: COUNTRIES,
    selectedCountry: SELECTED_COUNTRY,
    userPhoneNumber: null,
  }),
  computed: {
    ...mapState(useSettingsStore, ["getAccount"]),
  },
  created() {
    if (this.e164ContactNumber) return;

    if (this.getAccount === undefined || this.getAccount.dialCode === undefined)
      return;

    this.selectedCountry = this.countries.find((country) => {
      const dialCodesMatch = country.dialCode === this.getAccount.dialCode.code;
      if (country.index === null) return dialCodesMatch;

      return dialCodesMatch && country.index === this.getAccount.dialCode.index;
    });
  },
  methods: {
    handleSelectCountry(country) {
      this.selectedCountry = country;
      this.validateNumber();
    },
    updatePhoneNumber(e) {
      this.userPhoneNumber = e.target.value;
      this.validateNumber();
    },
    validateNumber() {
      if (!this.selectedCountry.dialCode || !this.userPhoneNumber) {
        return;
      }

      const plusE164 = `+${this.selectedCountry.dialCode}${this.userPhoneNumber}`;
      if (!isValidPhoneNumber(plusE164)) {
        this.$emit("onUpdate", null);
        return;
      }

      this.$emit("onUpdate", parsePhoneNumber(plusE164).number);
    },
    setCountry(countryCode) {
      this.selectedCountry = this.countries.find(
        (country) => country.code === countryCode,
      );
    },
    handleFormDataPhoneNumber() {
      if (this.e164ContactNumber) {
        const phoneNumber = parsePhoneNumber(this.e164ContactNumber);
        if (phoneNumber) {
          this.userPhoneNumber = phoneNumber.nationalNumber;
          this.setCountry(phoneNumber.country);
        }
      }
    },
  },
  watch: {
    e164ContactNumber: {
      immediate: true,
      handler: function () {
        if (!this.initialised && this.e164ContactNumber) {
          this.handleFormDataPhoneNumber();
          this.initialised = true;
        }
      },
    },
  },
};
</script>
