import firebase from "firebase/compat/app";

const ServerValue = firebase.database.ServerValue;
const presenceRef = firebase.database().ref("presence");

export const ActivityState = Object.freeze({
  ACTIVE: "active",
  OFFLINE: "offline",
  IDLE: "idle",
});

export function handleUserPresence(uid) {
  const userPresenceRef = presenceRef.child(uid);

  const isOnlineForDatabase = {
    state: ActivityState.ACTIVE,
    timestamp_active: ServerValue.TIMESTAMP,
  };

  const isOfflineForDatabase = {
    state: ActivityState.OFFLINE,
    timestamp_offline: ServerValue.TIMESTAMP,
  };

  firebase
    .database()
    .ref(".info/connected")
    .on("value", function (snapshot) {
      if (snapshot.val() === false) {
        return;
      }

      userPresenceRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          userPresenceRef.update(isOnlineForDatabase);
        });
    });

  // if the user's state changed to offline (likely because of another tab being closed)
  // while we're still connected then set the user back to active
  userPresenceRef.on("value", (snapshot) => {
    if (ActivityState.OFFLINE === snapshot.val()?.state) {
      setUserActive(uid);
    }
  });
}

export function startListeningToPresenceUsersActive(callback) {
  return startListeningToPresenceUsers(ActivityState.ACTIVE, callback);
}

export function startListeningToPresenceUsersIdle(callback) {
  return startListeningToPresenceUsers(ActivityState.IDLE, callback);
}

export function startListeningToPresenceUsers(state, callback) {
  return presenceRef
    .orderByChild("state")
    .equalTo(state)
    .on(
      "value",
      (snapshot) => {
        const userStats = snapshot.val();
        callback(userStats ? Object.keys(userStats).length : 0);
      },
      (error) => {
        console.error(error);
      },
    );
}

export function stopListeningToPresenceUsers(listener) {
  if (listener) {
    presenceRef.off("value", listener);
  }
}

export async function setUserActive(uid) {
  try {
    const userPresenceRef = presenceRef.child(uid);
    await userPresenceRef.update({
      state: ActivityState.ACTIVE,
      timestamp_active: ServerValue.TIMESTAMP,
    });
  } catch (error) {
    console.error("Failed to update user activity status:", error);
  }
}

export async function setUserIdle(uid) {
  try {
    const userPresenceRef = presenceRef.child(uid);
    await userPresenceRef.update({
      state: ActivityState.IDLE,
      timestamp_idle: ServerValue.TIMESTAMP,
    });
  } catch (error) {
    console.error("Failed to update user activity status:", error);
  }
}
