import App from "../views/app/App.vue";
import Home from "../views/app/Home.vue";
import firebase from "firebase/compat/app";
import { settingsCollection, profilesCollection } from "@/firebaseCompat.js";
import { hasPermission } from "@/permissions.js";
import * as Sentry from "@sentry/vue";
import { createRouter, createWebHistory } from "vue-router";
import { REDIRECT_QUERY_KEY } from "@/constants.js";
import { ERRORS } from "@/constants/index.js";
import { SETTINGS_DOCUMENTS } from "@/constants/index.js";
import { useSettingsStore, useUserStore, STATUS } from "@/state";

const routes = [
  {
    path: "/",
    component: App,
    redirect: { name: "App" },
    meta: {
      requiresAuthData: true,
    },
    children: [
      {
        name: "home",
        path: "",
        component: Home,
        meta: {
          requiresAuthData: true,
        },
      },
      {
        name: "unit",
        path: "unit/:name",
        component: () => import("../views/app/Unit.vue"),
      },
      {
        name: "unitEstate",
        path: "estate/:name",
        component: () => import("../views/app/UnitEstate.vue"),
      },
      {
        name: "onedayonlyunit",
        path: "onedayonly/:name",
        component: () => import("../views/app/Unit.vue"),
      },
      {
        name: "reserve",
        path: "reserve/:name",
        component: () => import("../views/app/Reserve-2.vue"),
      },
      {
        name: "profile",
        path: "profile",
        component: () => import("../views/app/Profile.vue"),
      },
      {
        name: "thankyou",
        path: "/thankyou",
        component: () => import("../views/app/ThankYou.vue"),
      },
      {
        name: "cancel",
        path: "/cancel/:id",
        component: () => import("../views/app/Cancel.vue"),
      },
      {
        path: "/survey",
        name: "survey",
        component: () => import("../views/app/Survey.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/admin/Admin.vue"),
    redirect: { name: "admin" },
    meta: {
      requiresAuthData: true,
      requiresAdminRole: true,
    },
    children: [
      {
        name: "admin",
        path: "dashboard",
        component: () => import("../views/admin/Dashboard-v2.vue"),
        meta: {
          area: "dashboard",
          action: "view",
        },
      },
      {
        name: "admin-units",
        path: "units",
        component: () => import("../views/admin/Units.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-available-units",
        path: "units-available",
        component: () => import("../views/admin/AvailableUnits.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-pending-units",
        path: "units-pending",
        component: () => import("../views/admin/PendingUnits.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-unitsv2",
        path: "unitsv2",
        component: () => import("../views/admin/Unitsv2.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-deals",
        path: "deals",
        component: () => import("../views/admin/Deals.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-reserved-units",
        path: "units-reserved",
        component: () => import("../views/admin/ReservedUnits.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-sold-units",
        path: "units-sold",
        component: () => import("../views/admin/SoldUnits.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-shortlisted-units",
        path: "units-shortlisted",
        component: () => import("../views/admin/ShortlistedUnits.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-unit-shortlisters",
        path: "unit-shortlisters/:id",
        component: () => import("../views/admin/UnitShortlisters.vue"),
        meta: {
          area: "units",
          action: "view",
        },
      },
      {
        name: "admin-add-unit",
        path: "unit",
        component: () => import("../views/admin/Unit.vue"),
        meta: {
          area: "units",
          action: "add",
        },
      },
      {
        name: "admin-update-unit",
        path: "unit/:id",
        component: () => import("../views/admin/Unit.vue"),
        meta: {
          area: "units",
          action: "update",
        },
      },
      {
        name: "admin-plans-configuration",
        path: "plans-configuration",
        component: () => import("../views/admin/PlansConfiguration"),
        meta: {
          area: "plans",
          action: "view",
        },
      },
      {
        name: "admin-plans-edit",
        path: "plans/:id",
        component: () =>
          import("../views/admin/PlansConfiguration/EditPlan.vue"),
        meta: {
          area: "plans",
          action: "update",
        },
      },
      {
        name: "admin-users",
        path: "users",
        component: () => import("../views/admin/Users.vue"),
        meta: {
          area: "users",
          action: "view",
        },
      },
      {
        name: "admin-attendants",
        path: "attendants",
        component: () => import("../views/admin/SocialChatSettings.vue"),
        meta: {
          area: "attendants",
          action: "view",
        },
      },
      {
        name: "admin-integrations",
        path: "integrations",
        component: () => import("../views/admin/Integrations/index.js"),
        meta: {
          area: "integrations",
          action: "view",
        },
      },
      {
        name: "admin-theme",
        path: "theme",
        component: () => import("../views/admin/Theme.vue"),
        meta: {
          area: "theme",
          action: "view",
        },
      },
      {
        name: "admin-history",
        path: "history",
        component: () => import("../views/admin/History.vue"),
        meta: {
          area: "history",
          action: "view",
        },
      },
      {
        name: "admin-account",
        path: "account",
        component: () => import("../views/admin/Account"),
        meta: {
          area: "account",
          action: "view",
        },
      },
      {
        name: "admin-survey",
        path: "survey",
        component: () => import("../views/admin/Survey.vue"),
        meta: {
          area: "survey",
          action: "view",
        },
      },
      {
        name: "admin-agents",
        path: "agents",
        component: () => import("../views/admin/Agents.vue"),
        meta: {
          area: "agents",
          action: "view",
        },
      },
      {
        name: "admin-add-agent",
        path: "agents",
        component: () => import("../views/admin/Agent.vue"),
        meta: {
          area: "agents",
          action: "add",
        },
      },
      {
        name: "admin-add-attendant",
        path: "attendants",
        component: () => import("../views/admin/SocialChatSetting.vue"),
        meta: {
          area: "attendants",
          action: "add",
        },
      },
      {
        name: "admin-update-agent",
        path: "agent/:id",
        component: () => import("../views/admin/Agent.vue"),
        meta: {
          area: "agents",
          action: "update",
        },
      },
      {
        name: "admin-update-attendant",
        path: "attendant/:id",
        component: () => import("../views/admin/SocialChatSetting.vue"),
        meta: {
          area: "attendants",
          action: "update",
        },
      },
      {
        name: "admin-users-spamming",
        path: "users-spamming",
        component: () => import("../views/admin/StopSpammingUsers.vue"),
        meta: {
          area: "users",
          action: "view",
        },
      },
      {
        name: "admin-email-templates",
        path: "email-templates",
        component: () => import("../views/admin/EmailTemplates.vue"),
        meta: {
          area: "email-templates",
          action: "view",
        },
      },
      {
        name: "admin-update-email-template",
        path: "email-templates/:id",
        component: () => import("../views/admin/EmailTemplate.vue"),
        meta: {
          area: "email-templates",
          action: "update",
        },
      },
      {
        name: "admin-add-email-template",
        path: "email-template",
        component: () => import("../views/admin/EmailTemplate.vue"),
        meta: {
          area: "email-templates",
          action: "add",
        },
      },
      {
        name: "admin-data",
        path: "data",
        component: () => import("../views/admin/Data.vue"),
        meta: {
          area: "data",
          action: "view",
        },
      },
      {
        name: "admin-settings",
        path: "settings",
        component: () => import("../views/admin/Settings.vue"),
        meta: {
          area: "settings",
          action: "view",
        },
      },
      {
        name: "admin-extra-settings",
        path: "extraSettings",
        component: () => import("../views/admin/ExtrasSettings.vue"),
        meta: {
          area: "extraSettings",
          action: "view",
        },
      },
      {
        name: "admin-downloads",
        path: "downloads",
        component: () => import("../views/admin/Downloads.vue"),
        meta: {
          area: "downloads",
          action: "view",
        },
      },
      {
        name: "admin-import-estate",
        path: "import-estate",
        component: () => import("../views/admin/ImportEstate.vue"),
        meta: {
          area: "units",
          action: "import",
        },
      },
      {
        name: "admin-import",
        path: "import",
        component: () => import("../views/admin/ImportUnits.vue"),
        meta: {
          area: "units",
          action: "import",
        },
      },
    ],
  },
  {
    name: "stopSpammingUser",
    path: "/stopSpammingUser",
    component: () => import("../views/global/stopSpammingUser.vue"),
  },
  {
    name: "MaintenancePage",
    path: "/MaintenancePage",
    component: () => import("../views/global/MaintenancePage.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/global/Login.vue"),
  },
  {
    name: "register",
    path: "/register",
    component: () => import("../views/global/Register.vue"),
  },
  {
    name: "reset-password",
    path: "/resetpassword",
    component: () => import("../views/global/ResetPassword.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/global/404.vue"),
  },
];

// Added this because I got tired of adding console logs to the routes
const DEBUG_MODE = false;

function debugLog(...args) {
  if (DEBUG_MODE) {
    console.log(...args);
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const getGlobalSettings = async () => {
  const { getGlobalSettings } = useSettingsStore();
  if (getGlobalSettings !== undefined) return getGlobalSettings;

  try {
    const doc = await settingsCollection
      .doc(SETTINGS_DOCUMENTS.GLOBAL_SETTINGS)
      .get();
    const data = doc.data();
    if (!doc.exists || data === undefined) {
      throw new Error(ERRORS.GLOBAL_SETTINGS_NOT_FOUND);
    }

    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

let requiresAuth;
let requiresAdminRole;
let authenticatedUser;

// CONFIG (some of this should likely be moved to a store)
router.beforeEach(async (to) => {
  debugLog("CONFIG>>");

  requiresAuth = to.matched.some((record) => record.meta.requiresAuthData);
  requiresAdminRole = to.matched.some(
    (record) => record.meta.requiresAdminRole,
  );

  const { status, user } = useUserStore();
  if (status === STATUS.LISTENING) {
    authenticatedUser = user;
    return;
  }

  const firebaseUser = await new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });

  if (!firebaseUser || firebaseUser.isAnonymous) return;

  try {
    const doc = await profilesCollection.doc(firebaseUser.uid).get();
    const data = doc.data();
    if (!doc.exists || data === undefined) {
      throw new Error(ERRORS.PROFILE_NOT_FOUND);
    }
    authenticatedUser = data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
});

// ADMIN GUARD
router.beforeEach(async (to) => {
  debugLog("ADMIN>>");
  if (!requiresAdminRole) {
    return;
  }

  if (!authenticatedUser) {
    // not a logged in user, kick 'em
    return { name: "login" };
  }

  // TODO: this should rather be a whitelist instead of a blacklist
  if (authenticatedUser.role === "user") return { name: "login" };

  const meta = to.matched[to.matched.length - 1].meta;

  if (!meta || !hasPermission(meta.area, meta.action, authenticatedUser.role))
    return { name: "not-found" };
});

// ANON GUARD
router.beforeEach(async ({ path, query }) => {
  debugLog("ANON>>");

  const { dataCollection: platformRequiresAuth } = await getGlobalSettings();

  if (!platformRequiresAuth) return;

  if (!requiresAuth) return;

  if (authenticatedUser) return;

  return {
    name: "login",
    query: {
      ...query,
      [REDIRECT_QUERY_KEY]: path !== "/" ? path.slice(1) : undefined,
    },
  };
});

// MAINTENANCE GUARD
router.beforeEach(async () => {
  debugLog("MAINTENANCE>>");
  if (requiresAuth) {
    // const globals = await getGlobals();
    const globals = await getGlobalSettings();
    if (
      // showFailToPay is basically showMaintenance
      globals.showFailToPay &&
      (!authenticatedUser || authenticatedUser.role === "user")
    ) {
      // only users that don't have the role "user" are allowed to bypass
      return { name: "MaintenancePage" };
    }
  }
});

// SPAM GUARD
router.beforeEach(async () => {
  debugLog("SPAM>>");
  if (requiresAuth) {
    if (
      authenticatedUser &&
      authenticatedUser.stopSpamming &&
      authenticatedUser.stopSpamming === true
    ) {
      return { name: "stopSpammingUser" };
    }
  }
});

// Fixing chunk loading issues with this bit lifted from https://stackoverflow.com/a/74861436
router.onError((error, to) => {
  debugLog("ON_ERROR>>");
  if (
    // only reload the page if .js files not found, we don't want a reload if it's a .vue file that can't load (which might happen during dev).
    (error.message.includes("Failed to fetch dynamically imported module") &&
      error.message.endsWith(".js")) ||
    error.message.includes("Importing a module script failed") ||
    error.message.includes("Unable to preload CSS for") // not 100% sure if this will result in a loop or not
  ) {
    console.warn("New deployment detected.");
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});

export default router;
