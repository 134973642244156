<template>
  <span
    class="text-sm font-bold sm:text-xl tracking-wider text-navbar-text"
    v-if="this.unitsNotAvailable"
  >
    <template v-if="!this.settings.showAvailableTracker">
      {{
        windowWidth <= 640
          ? `${unitsNotAvailable} OF ${getSellableUnits()} `
          : `${unitsNotAvailable} OF ${getSellableUnits()} ${upperCaseUnitOrPlotOrEstate}`
      }}
      SOLD
    </template>
    <template v-else
      >{{ unitsAvailable }} UNIT{{ unitsAvailable === 1 ? "" : "S" }} AVAILABLE
    </template>
  </span>
</template>

<script>
import { unitsCollection } from "@/firebaseCompat.js";
import { mapState } from "pinia";
import { useSettingsStore } from "@/state";

export default {
  name: "TimerMessage",
  data: () => ({
    stopListeningToSellableUnits: undefined,
    stopListeningToUnitsNotAvailable: undefined,
    windowWidth: window.innerWidth,
    unitsNotAvailable: undefined,
    sellableUnits: 0,
  }),
  computed: {
    ...mapState(useSettingsStore, {
      settings: "getGlobalSettings",
    }),
    upperCaseUnitOrPlotOrEstate: function () {
      if (this.settings.layoutStyle[0] === "floor") {
        return "UNITS";
      } else if (this.settings.layoutStyle[0] === "plot size") {
        return "PLOTS";
      } else if (this.settings.layoutStyle[0] === "estate") {
        return "HOME";
      }
      return "UNITS";
    },
    unitsAvailable() {
      if (!this.settings.isMaxReservableUnits)
        return Math.max(0, this.sellableUnits - this.unitsNotAvailable);

      return Math.max(
        0,
        Math.min(this.settings.maxReservableUnits, this.sellableUnits) -
          this.unitsNotAvailable,
      );
    },
  },
  created() {
    this.stopListeningToSellableUnits = unitsCollection.onSnapshot((snap) => {
      this.sellableUnits = snap.size;
    });

    this.stopListeningToUnitsNotAvailable = unitsCollection
      .where("status", "in", [
        "pending",
        "pendingUnitDetails",
        "pendingPayment",
        "reserved",
        "sold",
      ])
      .onSnapshot((snap) => {
        this.unitsNotAvailable = snap.size;
      });
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  beforeUnmount() {
    if (this.stopListeningToSellableUnits) this.stopListeningToSellableUnits();
    if (this.stopListeningToUnitsNotAvailable)
      this.stopListeningToUnitsNotAvailable();
  },
  methods: {
    getSellableUnits() {
      const isMaxReservableUnits = this.settings.isMaxReservableUnits;
      const maxReservableUnitsAvailable = this.settings.maxReservableUnits > 0;

      if (isMaxReservableUnits && maxReservableUnitsAvailable) {
        return this.settings.maxReservableUnits;
      } else {
        return this.sellableUnits;
      }
    },
  },
};
</script>
