// myMixin.js
import { mapGetters } from "vuex";

export const netReturn = {
  data() {
    return {
      mixinData: {},
      mixinUser: {},
    };
  },
  methods: {
    setMixinUser(newValue) {
      this.mixinUser = newValue;
    },
    setMixinData(newValue) {
      this.mixinData = newValue;
    },
    PMT(ir, np, pv, fv, type) {
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt.toFixed(0);
    },
    mixinEstBondCost() {
      if (
        this.globals[0].allowGlobalDiscount &&
        this.mixinUser.loggedIn &&
        this.mixinUser.profile.answeredDiscountSurvey
      ) {
        // <!-- Unit Financials Bond Cost Global Discount Type === Amount -->
        if (this.globals[0].globalDiscountType === "amount") {
          return this.mixinEstBondCostCurrency();
        }
        // <!-- Unit Financials Bond Cost Global Discount Type === Percentage -->
        if (this.globals[0].globalDiscountType === "percentage") {
          return this.mixinEstBondCostPercentage();
        }
      } else if (this.globals[0].allowDiscountNoSurvey) {
        //  <!-- Unit Financials Bond Cost Global Discount Type === Amount -->
        if (this.globals[0].globalDiscountType === "amount") {
          return this.mixinEstBondCostCurrency();
        }
        // <!-- Unit Financials Bond Cost Global Discount Type === Percentage -->
        if (this.globals[0].globalDiscountType === "percentage") {
          return this.mixinEstBondCostPercentage();
        }
      } else {
        // <!-- Unit Financials Bond Cost Global Discount Type === NONE -->
        return this.mixinEstBondCostDefault();
      }
    },
    mixinTotalMonthlyCost() {
      if (
        this.globals[0].allowGlobalDiscount &&
        this.mixinUser.loggedIn &&
        this.mixinUser.profile.answeredDiscountSurvey
      ) {
        // <!-- Unit Financials Monthly Cost Global Discount Type === Amount -->
        if (
          this.globals[0].globalDiscountType === "amount" &&
          this.mixinData.levies &&
          this.mixinData.rates
        ) {
          return this.mixinTotalMonthlyCostCurrency();
        }
        // <!-- Unit Financials Monthly Cost Global Discount Type === Percentage -->
        if (
          this.globals[0].globalDiscountType === "percentage" &&
          this.mixinData.levies &&
          this.mixinData.rates
        ) {
          return this.mixinTotalMonthlyCostPercentage();
        }
      } else if (this.globals[0].allowDiscountNoSurvey) {
        //  <!-- Unit Financials Monthly Cost Global Discount Type === Amount -->
        if (
          this.globals[0].globalDiscountType === "amount" &&
          this.mixinData.levies &&
          this.mixinData.rates
        ) {
          this.mixinTotalMonthlyCostCurrency();
        }
        // <!-- Unit Financials Monthly Cost Global Discount Type === Percentage -->
        if (
          this.globals[0].globalDiscountType === "percentage" &&
          this.mixinData.levies &&
          this.mixinData.rates
        ) {
          return this.mixinTotalMonthlyCostPercentage();
        }
      } else {
        //  <!-- Unit Financials Monthly Cost Global Discount Type === NONE -->
        return this.mixinTotalMonthlyCostDefault();
      }
    },
    mixinEstRental() {
      if (
        this.mixinData.guaranteedRental &&
        parseInt(this.mixinData.guaranteedRental, 10) > 0
      ) {
        return this.mixinData.guaranteedRental.toFixed(0);
      } else {
        return 0;
      }
    },
    mixinNetReturn() {
      if (
        this.globals[0].allowGlobalDiscount &&
        this.mixinUser.loggedIn &&
        this.mixinUser.profile.answeredDiscountSurvey
      ) {
        // Unit Financials Net Return Global Discount Type === Amount
        if (this.globals[0].globalDiscountType === "amount") {
          if (
            this.mixinData.guaranteedRental &&
            this.mixinData.levies &&
            this.mixinData.rates
          ) {
            return this.mixinNetReturnCurrency();
          }
        }
        // Unit Financials Net Return Global Discount Type === Percentage
        if (this.globals[0].globalDiscountType === "percentage") {
          if (
            this.mixinData.guaranteedRental &&
            this.mixinData.levies &&
            this.mixinData.rates
          ) {
            return this.mixinNetReturnPercentage();
          }
        }
      } else if (this.globals[0].allowDiscountNoSurvey) {
        // Unit Financials Net Return Global Discount Type === Amount
        if (this.globals[0].globalDiscountType === "amount") {
          if (
            this.mixinData.guaranteedRental &&
            this.mixinData.levies &&
            this.mixinData.rates
          ) {
            this.mixinNetReturnCurrency();
          }
        }
        // Unit Financials Net Return Global Discount Type === Percentage
        if (this.globals[0].globalDiscountType === "percentage") {
          if (
            this.mixinData.guaranteedRental &&
            this.mixinData.data.levies &&
            this.mixinData.data.rates
          ) {
            return this.mixinNetReturnPercentage();
          }
        }
      } else {
        //  <!-- Unit Financials Net Return Global Discount Type === NONE -->
        if (
          this.mixinData.guaranteedRental &&
          this.mixinData.levies &&
          this.mixinData.rates
        ) {
          return this.mixinNetReturnDefault();
        }
      }
    },
    mixinMonthlyShortFall() {
      if (
        this.globals[0].allowGlobalDiscount &&
        this.mixinUser.loggedIn &&
        this.mixinUser.profile.answeredDiscountSurvey &&
        this.mixinData.guaranteedRental &&
        this.mixinData.levies &&
        this.mixinData.rates
      ) {
        // Unit Financials Short Fall Global Discount Type === Amount
        if (this.globals[0].globalDiscountType === "amount") {
          return this.mixinMonthlyShortFallCurrency();
        }

        // Unit Financials Short Fall Global Discount Type === Percentage
        if (this.globals[0].globalDiscountType === "percentage") {
          return this.mixinMonthlyShortFallPercentage();
        }
      } else if (
        this.globals[0].allowDiscountNoSurvey &&
        this.mixinData.guaranteedRental &&
        this.mixinData.levies &&
        this.mixinData.rates
      ) {
        // Unit Financials Short Fall Global Discount Type === Amount
        if (this.globals[0].globalDiscountType === "amount") {
          return this.mixinMonthlyShortFallCurrency();
        }

        // Unit Financials Short Fall Global Discount Type === Percentage
        if (this.globals[0].globalDiscountType === "percentage") {
          return this.mixinMonthlyShortFallPercentage();
        }
      } else {
        //  <!-- Unit Financials Short Fall Global Discount Type === NONE -->
        return this.mixinMonthlyShortFallDefault();
      }
    },
    mixinExpensesDefault() {
      const expenseOne = this.mixinData.expense1
        ? parseFloat(this.mixinData.expense1)
        : 0;
      const expenseTwo = this.mixinData.expense2
        ? parseFloat(this.mixinData.expense2)
        : 0;
      const expenseThree = this.mixinData.expense3
        ? parseFloat(this.mixinData.expense3)
        : 0;

      const expense = expenseOne + expenseTwo + expenseThree;
      return parseFloat(expense);
    },
    mixinNetReturnCurrency() {
      const netReturn = (
        (((this.mixinData.guaranteedRental -
          this.mixinData.levies -
          this.mixinData.rates -
          this.mixinExpensesDefault()) *
          12) /
          (this.mixinData.price - this.globals[0].globalDiscountAmount)) *
        100
      ).toFixed(2);

      return parseFloat(netReturn);
    },
    mixinNetReturnPercentage() {
      const netReturn = (
        (((this.mixinData.guaranteedRental -
          this.mixinData.levies -
          this.mixinData.rates -
          this.mixinExpensesDefault()) *
          12) /
          (this.mixinData.price *
            ((100 - this.globals[0].globalDiscountAmount) / 100))) *
        100
      ).toFixed(2);

      return parseFloat(netReturn);
    },
    mixinNetReturnDefault() {
      const netReturn = (
        (((this.mixinData.guaranteedRental -
          this.mixinData.levies -
          this.mixinData.rates -
          this.mixinExpensesDefault()) *
          12) /
          this.mixinData.price) *
        100
      ).toFixed(2);

      return parseFloat(netReturn);
    },
    mixinEstBondCostCurrency() {
      const estBondCost = this.PMT(
        this.globals[0].interestRate / 100 / 12,
        20 * 12,
        (this.mixinData.price - this.globals[0].globalDiscountAmount) *
          0.9 *
          -1,
        0,
        0,
      );

      return parseFloat(estBondCost);
    },
    mixinEstBondCostPercentage() {
      const estBondCost = this.PMT(
        this.globals[0].interestRate / 100 / 12,
        20 * 12,
        this.mixinData.price *
          ((100 - this.globals[0].globalDiscountAmount) / 100) *
          0.9 *
          -1,
        0,
        0,
      );

      return parseFloat(estBondCost);
    },
    mixinEstBondCostDefault() {
      const estBondCost = this.PMT(
        this.globals[0].interestRate / 100 / 12,
        20 * 12,
        this.mixinData.price * 0.9 * -1,
        0,
        0,
      );

      return parseFloat(estBondCost);
    },
    mixinTotalMonthlyCostCurrency() {
      const totalMonthlyCost = (
        this.mixinData.levies +
        this.mixinData.rates +
        this.mixinEstBondCostCurrency() +
        this.mixinExpensesDefault()
      ).toFixed(0);

      return parseFloat(totalMonthlyCost);
    },
    mixinTotalMonthlyCostPercentage() {
      const totalMonthlyCost = (
        this.mixinData.levies +
        this.mixinData.rates +
        this.mixinEstBondCostPercentage() +
        this.mixinExpensesDefault()
      ).toFixed(0);

      return parseFloat(totalMonthlyCost);
    },
    mixinTotalMonthlyCostDefault() {
      const totalMonthlyCost = (
        this.mixinData.levies +
        this.mixinData.rates +
        this.mixinEstBondCostDefault() +
        this.mixinExpensesDefault()
      ).toFixed(0);

      return parseFloat(totalMonthlyCost);
    },
    mixinMonthlyShortFallCurrency() {
      const shortfall = (
        this.mixinTotalMonthlyCostCurrency() - this.mixinData.guaranteedRental
      ).toFixed(0);

      return parseFloat(shortfall);
    },
    mixinMonthlyShortFallPercentage() {
      const shortfall = (
        this.mixinTotalMonthlyCostPercentage() - this.mixinData.guaranteedRental
      ).toFixed(0);

      return parseFloat(shortfall);
    },
    mixinMonthlyShortFallDefault() {
      const shortfall = (
        this.mixinTotalMonthlyCostDefault() - this.mixinData.guaranteedRental
      ).toFixed(0);
      return parseFloat(shortfall);
    },
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
  },
};
