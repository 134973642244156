<template>
  <nav
    id="navbar"
    class="flex w-full px-2 py-2 z-[100] sticky transition-all duration-300 ease-out space-x-2 bg-navbar-background"
    :class="[navbarClass, scrolled ? 'h-16' : 'h-28']"
  >
    <div class="flex flex-row w-1/4 sm:w-1/3 justify-start h-full px-2">
      <router-link to="/">
        <img id="logo" alt="logo" class="h-full object-contain" :src="logo" />
      </router-link>
    </div>

    <div class="flex flex-row w-1/2 sm:w-1/3 justify-center items-center">
      <!-- at the very least an empty div is required to maintain navbar spacing -->
      <TimerLaunch
        :msTillLaunchTime="msTillLaunchTime"
        :scrolled="this.scrolled"
      />
    </div>

    <div class="flex flex-row w-1/4 sm:w-1/3 justify-end items-center">
      <div v-if="windowWidth >= 640" class="flex flex-row items-center">
        <MainNavBarMenuDropdown id="v-step-menu" />
        <MainNavBarProfileDropdown
          id="v-step-profile"
          :msTillLaunchTime="msTillLaunchTime"
        />
      </div>

      <!-- Mobi -->
      <div v-else class="flex flex-row items-center">
        <MainNavBarMenuProfileDropdownMobi
          id="v-step-profile"
          :msTillLaunchTime="msTillLaunchTime"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import MainNavBarMenuDropdown from "./MainNavBarMenuDropdown.vue";
import MainNavBarProfileDropdown from "./MainNavBarProfileDropdown.vue";
import MainNavBarMenuProfileDropdownMobi from "./MainNavBarMenuProfileDropdownMobi.vue";
import TimerLaunch from "./TimerLaunch/TimerLaunch.vue";
import { mapGetters } from "vuex";
import { ref } from "vue";
import { useScroll } from "@vueuse/core";

export default {
  name: "NavBar",
  components: {
    TimerLaunch,
    MainNavBarMenuDropdown,
    MainNavBarProfileDropdown,
    MainNavBarMenuProfileDropdownMobi,
  },
  props: {
    launchDateTime: {
      type: Date,
    },
    msTillLaunchTime: {
      type: Number,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      scrollY: ref(0),
      scrolled: false,
    };
  },
  mounted() {
    const { y } = useScroll(window);
    this.scrollY = y;
  },
  watch: {
    scrollY(newVal) {
      if (this.scrolled && newVal === 0) {
        this.scrolled = false;
      }
      // 48 is the pixel difference between h-16 and h-28
      // this check must be more than this difference to prevent scroll jank
      if (!this.scrolled && newVal > 48) {
        this.scrolled = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      globals: "globals",
      onlineUsers: "onlineUsers",
    }),
    usersOnline: function () {
      return this.onlineUsers.usersActive + this.onlineUsers.usersIdle;
    },
    showOnlineUsers: function () {
      return (
        this.globals[0].isUsersOnline &&
        this.usersOnline > (this.globals[0].onlineUserThreshold || 0)
      );
    },
    navbarClass() {
      return this.showOnlineUsers ? "top-8" : "top-0";
    },
    logo() {
      return (
        this.globals[0].logo || "../../assets/images/default-nav-bar-logo.png"
      );
    },
  },
};
</script>
