import { onMounted, onUnmounted, computed, watch, ref } from "vue";
import { unitsCollection } from "@/firebaseCompat.js";
import { useSettingsStore } from "@/state/index.js";
import vuexStore from "../../store.js";

// THIS SHOULD RATHER BE MANAGED SERVER SIDE!
export function useMaxReservableUnitHandler() {
  // The number of units that have already at least been put into a pending state
  let unitsPendingBlockedCount = ref(0);
  let stopPendingBlockListener = undefined;

  onMounted(
    () =>
      (stopPendingBlockListener = unitsCollection
        .where("status", "!=", "available")
        .onSnapshot((snap) => {
          unitsPendingBlockedCount.value = snap.size;
          updateUnitAvailability();
        })),
  );
  onUnmounted(() => {
    if (stopPendingBlockListener !== undefined) stopPendingBlockListener();
  });

  // The number of units that have already at least been put into a reservation state
  let unitsReservationBlockedCount = ref(0);
  let stopReservationBlockedListener = undefined;

  onMounted(
    () =>
      (stopReservationBlockedListener = unitsCollection
        .where("status", "not-in", [
          "available",
          "pending",
          "pendingUnitDetails",
          "pendingPayment",
        ])
        .onSnapshot((snap) => {
          unitsReservationBlockedCount.value = snap.size;
          updateUnitAvailability();
        })),
  );
  onUnmounted(() => {
    if (stopReservationBlockedListener !== undefined)
      stopReservationBlockedListener();
  });

  const store = useSettingsStore();
  const globalSettings = computed(() => store.getGlobalSettings);

  watch(globalSettings, (newValue, oldValue) => {
    if (newValue.maxReservableUnits !== oldValue.maxReservableUnits) {
      updateUnitAvailability();
    }
  });

  function updateUnitAvailability() {
    vuexStore.dispatch(
      "handlePendingBlock",
      globalSettings.value.isMaxReservableUnits &&
        unitsPendingBlockedCount.value >=
          globalSettings.value.maxReservableUnits,
    );

    vuexStore.dispatch(
      "handleReservationBlock",
      globalSettings.value.isMaxReservableUnits &&
        unitsReservationBlockedCount.value >=
          globalSettings.value.maxReservableUnits,
    );
  }
}
