<template>
  <div class="home bg-surface-100">
    <PageBanner />
    <MainUnitReserveList :msTillLaunchTime="msTillLaunchTime" />
  </div>
</template>

<script>
import PageBanner from "../../components/app/PageBanner.vue";
import MainUnitReserveList from "../../components/app/MainUnitReserveList.vue";

export default {
  name: "HomeComponent",
  props: {
    msTillLaunchTime: {
      type: Number,
    },
  },
  components: {
    PageBanner,
    MainUnitReserveList,
  },
};
</script>
