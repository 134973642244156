import firebaseSdkConfig from "../firebaseSdkConfig.js";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import * as Sentry from "@sentry/vue";

const firebaseConfig = firebaseSdkConfig;

export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage();

export const functionsInstance = getFunctions(
  firebaseApp,
  import.meta.env.VITE_CF_REGION,
);

// COLLECTION REFERENCES
export const dealsRef = collection(firestore, "deals");
export const historyRef = collection(firestore, "_history");
export const plansRef = collection(firestore, "plans");
export const profilesRef = collection(firestore, "profiles");
export const settingsRef = collection(firestore, "settings");
export const unitsRef = collection(firestore, "units");

// VUEFIRE/SENTRY UTILS
export async function updateDocument(document, data) {
  try {
    await updateDoc(document, data);
  } catch (error) {
    Sentry.captureMessage(`Trying to update ${getPath(document)}: ${error}`);
    throw error;
  }
}

function getPath(document) {
  return document._key.path.segments.join("/");
}
