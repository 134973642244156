<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full">
    <button
      @click="toggleDropdown"
      class="block flex flex-row px-3 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline overflow-ellipsis"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedStatus === 'default'">
        {{ title }}
      </span>

      <span
        v-else
        class="flex flex-row overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ getStatusName() }}
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{
          'rotate-180': displayDropdown,
          'rotate-0': !displayDropdown,
        }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-click-outside="toggleDropdown"
      class="filter-container-status shadow-lg"
      :class="filterContainerClass"
    >
      <div
        class="pt-8 pb-5 px-5 dropdownContainer bg-white rounded-md shadow-lg"
      >
        <ul
          class="listContainerPadding max-h-28 md:max-h-36 lg:max-h-36 overflow-y-scroll font-normal tracking-wider cursor-pointer"
        >
          <li
            class="mb-2 text-sm"
            v-for="option in options"
            :key="option.id"
            :class="{ active: selectedStatus === option.id }"
            @click="setStatus(option.id)"
          >
            {{ option.text }}
          </li>
        </ul>
        <div class="px-4 pb-4">
          <button
            @click="toggleDropdown"
            class="bg-primary hover:bg-primary-600 p-1 mt-2 w-full border rounded text-white"
          >
            <span> Done </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainUnitReserveListFilterStatus",
  components: {},
  props: {
    propStatus: {
      type: String,
      default: "default",
    },
    isOutlook: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    windowWidth: window.innerWidth,
    displayDropdown: false,
    title: "Display",
    selectedStatus: "default",
    options: [
      { id: "all", text: "Showing All" },
      { id: "available-only", text: "Available Only" },
      { id: "available-and-pending", text: "Available & Pending" },
      // {id: "gr-only", text: "Guaranteed Rentals Only"},
      { id: "popularity", text: "Most Popular" },
      { id: "my-shortlist", text: "My Shortlist" },
    ],
    dropdownRevealedAt: null,
  }),
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getStatusName() {
      return this.options.find((item) => item.id === this.selectedStatus).text;
    },
    toggleDropdown(event) {
      // ensures that we don't toggle twice during the same event
      if (event.timeStamp === this.dropdownRevealedAt) return;

      this.dropdownRevealedAt = event.timeStamp;
      this.displayDropdown = !this.displayDropdown;
    },
    setStatus(selectedStatus) {
      this.selectedStatus = selectedStatus;

      this.$emit("update:status-filter", selectedStatus);
      this.$router.push({
        query: { ...this.$route.query, status: selectedStatus },
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters({
      globals: "globals",
    }),
    filterContainerClass() {
      const classList = ["filter-container-status"];
      if (this.windowWidth <= 750) {
        if (this.globals[0].layoutStyle[0] === "estate") {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              }
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              }
            }
          }
        } else {
          if (this.globals[0].isUnitTypes) {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              }
            }
          } else {
            if (this.globals[0].isAspectFilter) {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              }
            } else {
              if (this.globals[0].isOutlookFilter) {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-right");
                } else {
                  classList.push("filter-container-status-left");
                }
              } else {
                if (this.globals[0].isFloorsFilter) {
                  classList.push("filter-container-status-left");
                } else {
                  classList.push("filter-container-status-right");
                }
              }
            }
          }
        }
      } else {
        classList.push("filter-container-status-left");
      }
      return classList.join(" ");
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.selectedStatus = this.propStatus;
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (oldWidth !== newWidth) {
        this.windowWidth = newWidth;
      }
    },
    propStatus: {
      handler() {
        this.selectedStatus = this.propStatus;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.filter-container-status {
  position: absolute;
  transform-origin: top right;
  z-index: 20;
}

.filter-container-status-right {
  right: 0 !important;
}

.filter-container-status-left {
  left: 0 !important;
}

.dropdownContainer {
  width: 18rem;
}

.listContainerPadding {
  padding: 17px 17px 0;
}

.active {
  font-weight: bold;
}
</style>
