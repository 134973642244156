<template>
  <div
    :class="{ overlay: this.statuses.includes(unit.status) }"
    :style="dynamicOverlayStyle"
  >
    <p v-if="this.statuses.includes(unit.status)" class="status-label text-3xl">
      {{ this.pendingStatuses.includes(unit.status) ? "pending" : unit.status }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PendingOrPendingOrReserveOrSoldLayout",
  props: {
    unit: {
      default: {},
    },
  },
  data: () => ({
    pendingStatuses: ["pending", "pendingUnitDetails", "pendingPayment"],
    statuses: [
      "pending",
      "pendingUnitDetails",
      "pendingPayment",
      "reserved",
      "sold",
    ],
  }),
  computed: {
    dynamicOverlayStyle: function () {
      return {
        background: this.pendingStatuses.includes(this.unit.status)
          ? "rgba(0, 0, 0, 0.4)"
          : this.unit.status === "reserved"
            ? "rgba(0, 0, 0, 0.6)"
            : this.unit.status === "sold"
              ? "rgba(0, 0, 0, 0.8)"
              : null,

        zIndex: this.statuses.includes(this.unit.status) ? 20 : 0,
      };
    },
  },
};
</script>

<style lang="css" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 25%;
}
</style>
